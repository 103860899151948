import React, { useState, useEffect } from 'react';
import {
    Select, FormLabel, Box, Flex, Text, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl, Input,
    Button,
    useToast,
    Tooltip,
    Spinner,
} from '@chakra-ui/react';
import { useCreatePixelMutation, useGetPixelByAdaccountIdQuery } from 'store/facebookServices/facebookServicesApi';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const PixelConversionSelector = ({ errors,
    adsAccount,
    promotedObjectFields,
    promotedObjectData,
    onSelectedPixelId,
    onSelectedEventType, onBlur }) => {


    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()

    const { adsAccountId, adsAccountName } = adsAccount || {};

    const [selectedPixelId, setSelectedPixelId] = useState(promotedObjectData?.pixel_id);
    const [selectedEventType, setSelectedEventType] = useState(promotedObjectData?.custom_event_type || promotedObjectFields.custom_event_type?.options[0]?.value);

    const { data: pixelList, isLoading } = useGetPixelByAdaccountIdQuery(adsAccountId)
    const [createPixel, { }] = useCreatePixelMutation();

    const eventTypeOptions = promotedObjectFields.custom_event_type?.options || [];

    const handlePixelIdChange = (event) => {
        const selectedId = event.target.value;
        setSelectedPixelId(selectedId);
        onSelectedPixelId(selectedId, adsAccountId);
        // if (promotedObjectFields.custom_event_type?.options[0]?.value) {
        //     onSelectedEventType(promotedObjectFields.custom_event_type?.options[0]?.value, adsAccountId);
        // }
    };

    const handleEventTypeChange = (event) => {
        const selectedType = event.target.value;
        setSelectedEventType(selectedType);
        onSelectedEventType(selectedType, adsAccountId);
    };


    const validationSchema = Yup.object({
        pixelName: Yup.string().required('Pixel name is required'),
    });

    const handleSubmit = async (values, actions) => {
        const payload = { 'name': values.pixelName }

        await createPixel({ adsAccountId, payload }).unwrap().
            then((response) => {
                //console.log(response);
                if (response?.data) {
                    toast({
                        position: "top-right",
                        title: "Saved",
                        description: 'Pixel created',
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                // console.log(error?.message);
                if (error) (
                    toast({
                        position: "top-right",
                        title: "Error",
                        description: error?.message,
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    })
                )

            })
        actions.resetForm();
        onClose();
    };


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Pixel</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ pixelName: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <ModalBody>
                                    <FormControl id="pixel-name" isInvalid={errors.pixelName && touched.pixelName} isRequired>
                                        <FormLabel>Pixel Name</FormLabel>
                                        <Field
                                            name="pixelName"
                                            as={Input}
                                            placeholder="Enter pixel name"
                                        />
                                        {errors.pixelName && touched.pixelName ? (
                                            <div style={{ color: 'red.500', marginTop: '0.25rem' }}>{errors.pixelName}</div>
                                        ) : null}
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme="orange" mr={3} type="submit" size={'sm'}>
                                        Save
                                    </Button>
                                    <Button variant="ghost" onClick={onClose} size={'sm'}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>

            <Box mb={'10px'}>
                <Text fontWeight={'bold'}>Ad Account: {adsAccountName}</Text>
                {isLoading ? (
                    <Spinner size={'sm'} />
                ) : pixelList?.data?.length > 0 ? (
                    <Flex gap={'20px'}>
                        <Box flex={1}>
                            <Flex alignItems={'center'}>
                                <FormLabel htmlFor="pixel-id">Pixel</FormLabel>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text textAlign={'justify'}>
                                                <strong>About the pixel </strong> <br /> The pixel is a piece of code that you put on your website to measure the effectiveness of your ads. Choose the pixel that you want to use to track conversions.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" mb={2} />
                                </Tooltip>
                            </Flex>
                            <Select
                                id="pixel-id"
                                placeholder="Select Pixel ID"
                                value={selectedPixelId}
                                onChange={handlePixelIdChange}
                                bg={'#fff'}
                                size={'sm'}
                                borderRadius={'md'}
                                onBlur={onBlur}
                            >
                                {pixelList?.data?.map((pixel) => (
                                    <option key={pixel.id} value={pixel.id}>
                                        {pixel.name}
                                    </option>
                                ))}
                            </Select>
                        </Box>

                        <Box flex={1}>
                            <Flex alignItems={'center'}>
                                <FormLabel htmlFor="event-type">Conversion event</FormLabel>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text textAlign={'justify'}>
                                                Choose a conversion event associated with your Meta pixel. A conversion event is an action that you want People to take when they see your ads.
                                            </Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" mb={2} />
                                </Tooltip>
                            </Flex>
                            {eventTypeOptions.length === 1 ? (
                                <Text bg="#fff" borderRadius="md" p={2} fontSize={'14px'} height={'32px'} display="flex" alignItems="center">
                                    {eventTypeOptions[0].label}
                                </Text>
                            ) : (
                                <Select
                                    id="event-type"
                                    placeholder="Select Event Type"
                                    value={selectedEventType}
                                    onChange={handleEventTypeChange}
                                    bg={'#fff'}
                                    size={'sm'}
                                    // height={'32px'}
                                    isDisabled={!selectedPixelId}
                                    borderRadius={'md'}
                                >
                                    {eventTypeOptions.map((event) => (
                                        <option key={event.value} value={event.value}>
                                            {event.label}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </Box>
                    </Flex>
                ) : (
                    <Button onClick={onOpen}>Create new pixel</Button>
                )}
            </Box>
        </>
    );
};

export default PixelConversionSelector;
