import React, { useState, useRef, useEffect } from 'react';
import {
    Flex, Heading, Button, Spinner, Text, Box, IconButton, Spacer, Grid,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, useDisclosure, Image, Tabs, TabList, TabPanels, Tab, TabPanel, Divider, Input, InputGroup, InputRightElement, InputLeftElement, Select
} from '@chakra-ui/react';
import { AddIcon, SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { FiList, FiGrid } from 'react-icons/fi';
import { FaPlayCircle } from "react-icons/fa";
import SidebarWithHeader from 'components/SidebarWithHeader';
import UploadModal from 'components/StatusUploadModal';
import { useFetchVideosQuery, useFetchImagesQuery } from 'store/driveUpload/uploadApi';
import { getFileType } from 'utils/functions';
import { useSelector } from 'react-redux';
import { FiEye } from "react-icons/fi";


const DriveForClient = () => {
    const inputRef = useRef();
    const [showError, setShowError] = useState(false);
    const [view, setView] = useState('grid');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [previewFile, setPreviewFile] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
    const [selectedAdAccount, setSelectedAdAccount] = useState('');
    const [selectedTab, setSelectedTab] = useState('images');
    const userActiveAdaccounts = useSelector((state) => state.auth?.activeAdAccounts);

    const { data: images, isLoading: isImagesDataLoading, isFetching } = useFetchImagesQuery(
        { currentPage, searchValue: debouncedSearchValue, adsAccountId: selectedAdAccount?.adsAccountId },
        { skip: !selectedAdAccount?.adsAccountId || selectedTab !== 'images' }
    );

    const { data: videos, isLoading: isVideosDataLoading, refetch: refetchVideos, isFetching: isVideosFetching } = useFetchVideosQuery(
        { currentPage, searchValue: debouncedSearchValue, adsAccountId: selectedAdAccount?.adsAccountId },
        { skip: !selectedAdAccount?.adsAccountId || selectedTab !== 'videos' }
    );

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 800);

        return () => {
            clearTimeout(handler); // Cleanup timeout if searchValue changes before 800ms
        };
    }, [searchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2 || searchValue?.length == "") {
                setSearchValue(searchValue);
            }
        }, 4000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])

    useEffect(() => {
        if (searchValue.trim() === '') {
            setSearchValue('');
            setShowError(false);
            return;
        }

        const hasResults = (
            (selectedTab === 'images' && images?.data?.some(file => file.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
            (selectedTab === 'videos' && videos?.data?.some(file => file.name.toLowerCase().includes(searchValue.toLowerCase())))
        );

        setShowError(!hasResults);
    }, [searchValue, images, videos, selectedTab]);


    const handleFileClick = (file) => {
        if (file) {
            setPreviewFile({
                ...file,
                type: file.creativeType,
                value: file.creativeType == 'image' ? file.url : file.source,
            });
            setIsPreviewOpen(true);
        }
    };

    //callback for video upload
    const handleVideoUploadSuccess = () => {
        setTimeout(() => {
            refetchVideos();
        }, 5000);
    }

    useEffect(() => {
        if (userActiveAdaccounts?.length > 0) {
            setSelectedAdAccount(userActiveAdaccounts[0])
        }
    }, [userActiveAdaccounts])


    const imageList = images?.data
        ?.filter(file => !selectedAdAccount?.adsAccountId || file.adsAccount.adsAccountId === selectedAdAccount.adsAccountId)
        .map((file, index) => {
            const { icon } = getFileType(file.name);

            return (
                <Flex
                    direction="column"
                    key={index}
                    pb={view === 'grid' ? 0 : 2}
                >

                    {/* <Flex justify="space-between" align="center">
                        <Box display="flex" alignItems="center" maxW={view === 'grid' ? "210px" : ''} pt={'10px'}>
                            <Box boxSize="20px" display="flex" alignItems="center" justifyContent="center" ml={2}>
                                {icon}
                            </Box>

                        </Box>
                    </Flex> */}
                    <Box
                        bgColor={view === 'grid' ? 'gray.50' : ''}
                        borderRadius={view === 'grid' ? 'lg' : 'unset'}
                        borderBottomColor={view === 'grid' ? 'unset' : 'gray.50'}
                        borderBottomWidth={view === 'grid' ? 0 : 1}
                    >
                        {view === 'grid' ? (
                            <Flex
                                w="100%"
                                h="120px"
                                direction={'column'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                                borderRadius={3}
                                p={"2px"}
                                overflow={'hidden'}
                                onClick={() => {
                                    handleFileClick(file);
                                }}
                            >
                                {file.url ? (
                                    <>
                                        <Image
                                            src={file.url}
                                            alt={file.name}
                                            objectFit="cover"
                                            width="100%"
                                            height="100%"
                                            borderRadius="3px"
                                        />
                                        <Flex
                                            position="absolute"
                                            top="0"
                                            left="0"
                                            w="100%"
                                            h="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgColor="rgba(0, 0, 0, 0.3)"
                                            opacity="0"
                                            _hover={{
                                                opacity: 1,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FiEye size={18} color="white" />
                                        </Flex>
                                    </>

                                ) : (
                                    null
                                )}

                            </Flex>
                        ) : null}
                    </Box>
                    <Text fontSize="12px" noOfLines={1} ml={'10px'}>
                        {file.name}
                    </Text>
                </Flex >
            );
        })


    const videoList = videos?.data
        ?.filter(file => !selectedAdAccount?.adsAccountId || file.adsAccount.adsAccountId === selectedAdAccount.adsAccountId)
        .map((file, index) => {
            const { icon } = getFileType(file.name);
            return (
                <Flex
                    direction="column"
                    key={index}

                    pb={view === 'grid' ? 0 : 2}
                >
                    <Box
                        bgColor={view === 'grid' ? 'gray.50' : ''}
                        borderRadius={view === 'grid' ? 'lg' : 'unset'}
                        borderBottomColor={view === 'grid' ? 'unset' : 'gray.50'}
                        borderBottomWidth={view === 'grid' ? 0 : 1}
                    >
                        {view === 'grid' ? (
                            <Box
                                w="100%"
                                h="110px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                                borderRadius={3}
                                p={"2px"}
                                onClick={() => handleFileClick(file)}
                            >
                                {file.source ? (
                                    <Box w="100%" h="100%" position="relative">
                                        <video
                                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                            src={file.source}
                                            muted
                                        />
                                        <Flex
                                            position="absolute"
                                            top="0"
                                            left="0"
                                            w="100%"
                                            h="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgColor="rgba(0, 0, 0, 0.3)"
                                            opacity="0"
                                            _hover={{
                                                opacity: 1,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FaPlayCircle color="white" size="18px" />
                                        </Flex>
                                    </Box>
                                ) : (
                                    null
                                )}
                            </Box>
                        ) : null}
                    </Box>
                    <Text fontSize="13px" noOfLines={1} ml={2}>
                        {file.name}
                    </Text>
                </Flex>

            );
        })

    return (
        <SidebarWithHeader>
            <Flex mb={'10px'} alignItems="center">
                <Heading as='h1' size='xl'>My Drive</Heading>
                &nbsp;
                <Button colorScheme='orange' onClick={onOpen}>
                    <AddIcon boxSize={3} />&nbsp;Add New
                </Button>
                <Spacer />
                <IconButton
                    aria-label="List View"
                    icon={<FiList />}
                    ml="2"
                    onClick={() => setView('list')}
                    _hover={{
                        background: view === 'list' ? 'brand' : 'gray.300',
                    }}
                    sx={{
                        padding: '18px',
                        borderRadius: '50px 0 0 50px',
                        background: view === 'list' ? '#EA7869' : 'gray.100',
                        color: view === 'list' ? 'white' : 'inherit'
                    }}
                />
                <IconButton
                    aria-label="Grid View"
                    icon={<FiGrid />}
                    onClick={() => setView('grid')}
                    _hover={{
                        background: view === 'grid' ? 'brand' : 'gray.300',
                    }}
                    sx={{
                        padding: '18px',
                        borderRadius: '0 50px 50px 0',
                        background: view === 'grid' ? '#EA7869' : 'gray.100',
                        color: view === 'grid' ? 'white' : 'inherit'
                    }}
                />
            </Flex>

            <Flex alignItems="center">
                <Box width={'100%'}>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                            ref={inputRef}
                            type="text"
                            value={searchValue || ""}
                            onChange={e => {
                                setSearchValue(inputRef.current.value);
                            }}
                            placeholder={`Search...`}
                        />
                        {searchValue &&
                            <InputRightElement
                                cursor={'pointer'}
                                children={isFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                onClick={() => { setSearchValue(''); setShowError(false); }} />}
                    </InputGroup>
                    {showError ? (
                        <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                            <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                            {/* {searchValue ? (
                                    <Button size={'xs'} onClick={() => { setSearchValue(''); onSearchInputChange(''); }}>Reset</Button>
                                ) : null} */}
                        </Flex>
                    ) : null}

                </Box>
            </Flex>

            <Flex flexDirection={'column'} flex={'1'}>
                <Box mb={'20px'} gap={4}>
                    <Box mt={'20px'}>
                        <Tabs
                            size='sm'
                            position='relative'
                            variant='unstyled'
                            sx={{
                                display: "flex",
                                position: "relative",
                                flexDirection: "column",
                                flex: 1,
                            }}
                            onChange={(index) => setSelectedTab(index === 0 ? 'images' : 'videos')}
                            isLazy
                        >
                            <Flex justify={'space-between'}>
                                <TabList gap={"10px"}>
                                    <Tab

                                        borderWidth={1}
                                        borderRadius={5}
                                        _selected={{ color: "white", bg: "brand.200" }}
                                    >Images</Tab>
                                    <Tab
                                        borderWidth={1}
                                        borderRadius={5}
                                        _selected={{ color: "white", bg: "brand.200" }}
                                    >Videos</Tab>
                                </TabList>

                                <Select
                                    value={selectedAdAccount?.adsAccountId}
                                    onChange={(e) => {
                                        const selected = userActiveAdaccounts.find(
                                            (account) => account.adsAccountId === e.target.value
                                        );
                                        setSelectedAdAccount(selected);
                                    }}
                                    width="200px"
                                >
                                    {userActiveAdaccounts?.map((account) => (
                                        <option key={account.adsAccountId} value={account.adsAccountId}>
                                            {account.adsAccountName}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>

                            <Divider borderWidth={1} borderBottomColor={'gray.50'} my={'20px'} />

                            <TabPanels pb={"40px"}>
                                <TabPanel p={0}>
                                    {isImagesDataLoading ? (
                                        <Spinner mt={'20px'} />
                                    ) : (
                                        <>
                                            {images?.data?.length > 0 ? (
                                                <Grid templateColumns={view === 'grid' ? 'repeat( auto-fit, minmax(110px, 110px) )' : '1fr'} gap='20px'>
                                                    {imageList}
                                                </Grid>
                                            ) : (null
                                                // <Text>No image assets uploaded yet. Click on <strong>'Add New'</strong> to upload</Text>
                                            )}
                                        </>
                                    )}
                                </TabPanel>
                                <TabPanel p={0}>
                                    {isVideosDataLoading || isVideosFetching ? (
                                        <Spinner mt={'20px'} />
                                    ) : (
                                        <>
                                            {videos?.data?.length > 0 ? (
                                                <Grid templateColumns={view === 'grid' ? 'repeat( auto-fit, minmax(110px, 110px) )' : '1fr'} gap='20px'>
                                                    {videoList}
                                                </Grid>
                                            ) : (
                                                <Text>No video assets uploaded yet. Click on <strong>'Add New'</strong> to upload</Text>
                                            )}
                                        </>
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Box>
            </Flex>

            {/* Upload Modal */}
            <UploadModal
                onSuccess={handleVideoUploadSuccess}
                isOpen={isOpen}
                onClose={onClose}
            />

            {/* File Preview Modal */}
            <Modal isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {previewFile?.type == 'image' ? (
                            <Image src={previewFile.value} alt={previewFile.name} mx={'auto'} maxW={'100%'} maxH={'65vh'} borderRadius={2} />
                        ) : previewFile?.type == 'video' ? (
                            <video controls style={{ width: '100%' }}>
                                <source src={previewFile.value} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <Text>No preview available for this file type.</Text>
                        )}
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </SidebarWithHeader>
    );
};

export default DriveForClient;

