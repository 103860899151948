import { api } from "../api";

export const adspressoAdsetFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createAdspressoAdsets: builder.mutation({
            query: ({ payload }) => ({
                url: '/adspresso/adsets/',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['AdspressoAdsets', 'AdspressoCampaign'],
        }),
        // runAdspressAdsets: builder.mutation({
        //     query: (campaignId) => ({
        //         url: `/adspresso/adsets/${campaignId}`,
        //         method: 'POST',
        //     }),
        //     invalidatesTags: ['AdspressoAdsets'],
        // }),
        getAdspressoAdsetsByCampainId: builder.query({
            query: (campaignId) => ({
                url: `/adspresso/adsets/campaign/${campaignId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdspressoAdsets'],
        }),
        getAdspressoAdsetById: builder.query({
            query: (adsetId) => ({
                url: `/adspresso/adsets/${adsetId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        updateAdspressoAdset: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adspresso/adsets/${adsetId}`,
                method: 'PUT',
                body: payload
            }),
        }),
    }),
});

export const { useCreateAdspressoAdsetsMutation, useRunAdspressAdsetsMutation, useGetAdspressoAdsetByIdQuery, useGetAdspressoAdsetsByCampainIdQuery, useUpdateAdspressoAdsetMutation } = adspressoAdsetFormApi;
