import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    Flex,
    Tooltip,
    Icon,
    useDisclosure,
    useToast,
    FormControl,
    Switch,
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import AdSetUpdateForm from 'components/genericForms/AdsetUpdateForm';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { useGetAdsetsListQuery, useUpdateAdsetMutation, useUpdateAdsetStatusMutation } from 'store/campaigns/adsetFormApi';

function AdSetsTable({ adsAccountId }) {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [hasError, setHasError] = useState(false);
    const [selectedAdset, setselectedAdset] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const { data: adsetsList, isFetching, isLoading, error, refetch } = useGetAdsetsListQuery({
        currentPage,
        searchValue,
        adsAccountId
    }, { skip: !adsAccountId });

    const [updateAdsetStatus, { isLoading: isAdsetStatusUpdating }] = useUpdateAdsetStatusMutation({});


    const handleSaveSuccess = () => {
        refetch();
    };
    
    useEffect(() => {
        
        if (adsAccountId) {
            refetch();
        }
    }, [adsAccountId])

    useEffect(() => {
        if (adsetsList?.data) {
            const initialSwitchStates = {};
            adsetsList.data.forEach(adset => {
                initialSwitchStates[adset.adset_id] = adset.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [adsetsList]);

    useEffect(() => {
        if (error) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [error]);

    const handleSwitchChange = (adsetId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adsetId]: newStatus }));
        updateAdsetStatus({
            adsetId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Adset ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Adset status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Adset status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adsetId]: currentState }));
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Ad set ID",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isActive = switchStates[original.adset_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad set is ON' : 'Ad set is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Text fontSize={'12px'}>{original.adset_id}</Text>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.adset_id, original.status)}
                                    />
                                </FormControl>
                            </Tooltip>
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setselectedAdset(original);
                                            onOpen();
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                        </Flex>
                    )
                }
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.name}</Text>
                ),
            },
            {
                Header: "Destination Type",
                accessor: "WEBSITE",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.destination_type}</Text>
                )
            },
            {
                Header: "Optimization Goal",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.optimization_goal}</Text>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.status}</Text>
                ),
            },
        ],
        []
    );

    return (
        <>
            {/* EDIT ADSET MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={() => {onClose(); setselectedAdset(null)}}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AdSetUpdateForm adsetData={selectedAdset} onClose={onClose} onSaveSuccess={handleSaveSuccess} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {hasError ? (
                <Text mt="20px">Adset data not available.</Text>
                ) : isLoading ? (
                <Spinner mt="20px" />
                ) : adsetsList?.data?.length > 0 ? (
                <AppTableWithPagination
                    columns={columns}
                    data={adsetsList.data}
                    searchEnabled={true}
                    isLoading={false}
                    isFetching={isFetching}
                    initialState={{ pageIndex: currentPage - 1 }}
                    paginationMeta={adsetsList.meta || {}}
                    onPageChange={setCurrentPage}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
                ) : (
                <Text>
                    No Ad sets created yet. Go to the Adspresso tab and click on <strong>'Create'</strong> to begin.
                </Text>
           )}
        </>
    );

}

export default AdSetsTable;
