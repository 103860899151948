import { api } from '../api'

export const facebookServicesApi = api.injectEndpoints({

    endpoints: (builder) => ({
        getPixelById: builder.query({
            query: (pixelId) => `/ads-accounts/adspixels/${pixelId}/adspixel`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getPixelByAdaccountId: builder.query({
            query: (adsAccountId) => `/ads-accounts/${adsAccountId}/adspixels`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        createPixel: builder.mutation({
            query: ({ adsAccountId, payload }) => ({
                url: `/ads-accounts/${adsAccountId}/adspixels`,
                method: "POST",
                body: payload,
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                error.message = response.data.message;
                return error;
            },
        }),
        getFacebookPages: builder.query({
            query: (adsAccountId) => `/ads-accounts/${adsAccountId}/promote_pages`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getInstagramAccounts: builder.query({
            query: (adsAccountId) => `/ads-accounts/${adsAccountId}/instagram_accounts`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getGeoLocations: builder.query({
            query: (searchTerm) => `adsets/search?q=${searchTerm}&type=adgeolocation&limit=10`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getDetailedTargeting: builder.query({
            query: ({ searchTerm, adsAccountId }) => `adsets/targetingsearch/${adsAccountId}?q=${searchTerm}&limit=20`,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getLocales: builder.query({
            query: ({ searchTerm }) => `adsets/search?type=adlocale&q=${searchTerm}&limit=20`,
            transformResponse: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetPixelByAdaccountIdQuery,
    useGetPixelByIdQuery,
    useCreatePixelMutation,
    useGetFacebookPagesQuery,
    useGetInstagramAccountsQuery,
    useGetGeoLocationsQuery,
    useGetDetailedTargetingQuery,
    useGetLocalesQuery
} = facebookServicesApi;
