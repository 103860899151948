import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL, //'http://localhost:3001/api' 'https://media.blinklocal.in:3001/api'  process.env.REACT_APP_API_URL
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userInfo?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  //refetchOnFocus: true, // refetch on window focus
  //refetchOnReconnect: true, // refetch on internet connection
  //keepUnusedDataFor: 86400, // refresh cache if unused for 24 hrs
  tagTypes: [
    "AdAccounts",
    "UserAdAccounts",
    "Companies",
    "Company",
    "Request",
    "TopUps",
    "FileUpload",
    "AdspressoCampaign",
    "AdspressoCampaigns",
    "Campaigns",
    "AdsetForm",
    "AdspressoAdsets",
    "AdspressoAd",
  ],
  endpoints: () => ({}),
});
