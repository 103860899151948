import {
    Box,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup'; 
import { useEffect, useState } from 'react';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import DriveModal from 'components/drive/DriveModal';

const SingleAdUpdateForm = ({ adData, adsAccountData, onClose }) => {
    const toast = useToast();
    const { creative } = adData || {};
    const [initialValues, setInitialValues] = useState(null);
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const { data: adCreative, isFetching, isLoading, refetch } = useGetAdCreativeQuery(
        { creativeId: creative.id },
        { skip: !creative.id }
    );

    const [updateAd] = useUpdateAdMutation();

    useEffect(() => {
        if (adCreative) {
            const values = {
                name: adData?.name || "",
                creative: {
                    name: adCreative?.name || "",
                    object_story_spec: {
                        link_data: {
                            message: adCreative?.object_story_spec?.link_data?.message || "",
                            link: adCreative?.object_story_spec?.link_data?.link || "",
                            image_hash: adCreative?.object_story_spec?.link_data?.image_hash || ""
                        },
                        page_id: adCreative?.object_story_spec?.page_id || "",
                    },
                },
                image_url: adCreative?.image_url || "", 
                video_id: adCreative?.video_id || "",
            };
            setInitialValues(values);
        }
    }, [adCreative, adData]);

    // Schema
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        creative: Yup.object().shape({
            name: Yup.string().required('Headline is required'),
            object_story_spec: Yup.object().shape({
                link_data: Yup.object().shape({
                    message: Yup.string().required('Primary Text is required'),
                    link: Yup.string().url('Invalid URL').required('Link URL is required'),
                    image_hash: Yup.string().required('Image Hash is required')
                }),
                page_id: Yup.string().required('Facebook Page ID is required')
            })
        }),
        image_url: Yup.string().url('Invalid URL'),
    });

    const handleSubmit = (values, { setSubmitting }) => {

        const payload = {
            name: values.name,
            creative: {
                name: values.creative.name,
                image_url: selectedImage.url,
                object_story_spec: {
                    link_data: {
                        message: values.creative.object_story_spec.link_data.message,
                        link: values.creative.object_story_spec.link_data.link,
                        ...(initialValues.video_id ? { video_id: selectedImage.image_hash } : { image_hash: selectedImage.image_hash }),
                    },
                    page_id: values.creative.object_story_spec.page_id,
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: "OPT_OUT",
                        },
                    },
                },
                adsAccount: {
                    id: adsAccountData._id,
                    adsAccountId: adsAccountData.adsAccountId,
                    adsAccountName: adsAccountData.adsAccountName
                },
            },
        };

        updateAd({ creativeId: adData.id, payload })
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad updated",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                //console.log('Ad updated successfully:', response);
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error updating ad",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setSubmitting(false);
                onClose();
            });
    };

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleImageSelect = (file) => {

        if (file && file.length > 0) {
            const selectedFile = file[0]; 
            setSelectedImage({
                url: selectedFile?.url,
                image_hash: selectedFile?.creativeId, 
            });
            setInitialValues(prev => ({
                ...prev,
                image_url: selectedFile.url,
            }));
        }   
        setIsDriveModalOpen(false);
    }; 

    if (!initialValues) {
        return <Spinner mt={'20px'} />;
    }

    const mediaType = initialValues?.creative?.object_story_spec?.link_data?.image_hash 
    ? "image" 
    : (initialValues?.video_id ? "video" : null);

    
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {formik => {
                return(
                <Form>
                    <Box borderRadius="md" width="100%" maxWidth="600px" bg="gray.50" p={4}>
                        <Flex direction="column" mb={4}>
                            <Text fontWeight="bold">Ad Name</Text>
                            <Field
                                name="name"
                                as={Input}
                                placeholder="Enter your ad name here..."
                                bg="#fff"
                                size='sm'
                            />
                            {formik.touched.name && formik.errors.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" mb={4}>
                            <Text fontWeight="bold">Facebook Page ID</Text>
                            <FaceBookPageSelector
                                adsAccount={adsAccountData}
                                selectedPageId={formik.values.creative.object_story_spec.page_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                                }}
                            />
                            {formik.errors.creative?.object_story_spec?.page_id && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" mb={4}>
                            <Text fontWeight="bold">Headline</Text>
                            <Field
                                name="creative.name"
                                as={Input}
                                placeholder="Enter a headline"
                                bg="#fff"
                                size='sm'
                            />
                            {formik.touched.creative?.name && formik.errors.creative?.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.name}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" mb={4}>
                            <Text fontWeight="bold">Primary Text</Text>
                            <Field
                                name="creative.object_story_spec.link_data.message"
                                as={Input}
                                placeholder="Message"
                                bg="#fff"
                                size='sm'
                            />
                            {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                            )}
                        </Flex>

                        <Flex direction="column" mb={4}>
                            <Text fontWeight="bold">Link URL</Text>
                            <Field
                                name="creative.object_story_spec.link_data.link"
                                as={Input}
                                placeholder="Link URL"
                                bg="#fff"
                                size='sm'
                            />
                            {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                            )}
                        </Flex>

                        {/* <Flex direction="column" mb={4}>
                            <Text fontWeight="bold">Image Hash</Text>
                            <Field
                                name="creative.object_story_spec.link_data.image_hash"
                                as={Input}
                                placeholder="Image Hash"
                                bg="#fff"
                                size='sm'
                            />
                            {formik.touched.creative?.object_story_spec?.link_data?.image_hash && formik.errors.creative?.object_story_spec?.link_data?.image_hash && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.image_hash}</Text>
                            )}
                        </Flex> */}
                        <Text fontWeight="bold">Creative</Text>
                        <Flex direction="row" mb={4} gap={2} alignItems={'center'}>   
                            {/* <Field
                                name="image_url" 
                                as={Input}
                                placeholder="Enter image URL"
                                bg="#fff"
                                size='sm'
                            /> */}
                             <img
                                src={initialValues.image_url || ''} 
                                alt={initialValues?.name}
                                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                            />

                            <Button 
                                bg={'gray.50'}
                                size="xs"
                                borderWidth={1}
                                onClick={handleOpenDriveModal} mb={2}>
                                {mediaType === "image" ? "Change Image" : mediaType === "video" ? "Change Video" : "Upload Media"}
                            </Button>

                            {formik.touched.image_url && formik.errors.image_url && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.image_url}</Text>
                            )}
                        </Flex>

                        <Button
                            size="sm"
                            colorScheme="orange"
                            isLoading={formik.isSubmitting}
                            type="submit"
                            isDisabled={!formik.isValid}
                        >
                            Save
                        </Button>
                    </Box>

                     {/* Drive Modal */}
                     <DriveModal
                        isDriveModalOpen={isDriveModalOpen}
                        selectedAdAccounts={[{
                            id: adsAccountData._id,
                            adsAccountId: adsAccountData.adsAccountId,
                            adsAccountName: adsAccountData.adsAccountName,
                        }]} 
                        onClose={() => setIsDriveModalOpen(false)}
                        onSubmit={handleImageSelect} 
                        mediaType={mediaType}
                        uploadPurpose="update" 
                    />
                </Form>
            )}}
        </Formik>
    );
};

export default SingleAdUpdateForm;
