import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: null,
    isUserOnBoarded: false,
    hasActiveAdAccount: false,
    activeAdAccounts:null,
};

export const authSlice = createSlice({
    initialState,
    name: 'authSlice',
    reducers: {
        logout: (state) => {
            Object.assign(state, initialState)
            localStorage.removeItem('forexUser')
        },
        setUser: (state, { payload }) => {
            state.userInfo = payload;
        },
        setOnboarding: (state, { payload }) => {
            state.isUserOnBoarded = payload
        },
        setHasActiveAdAccount: (state, { payload }) => {
            state.hasActiveAdAccount = payload
        },
        setActiveUserAdAccounts: (state, { payload }) => {
            state.activeAdAccounts = payload
        }
    },
});

export default authSlice.reducer;

export const { logout, setUser, setOnboarding, setHasActiveAdAccount,setActiveUserAdAccounts } = authSlice.actions;