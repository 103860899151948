import {
    Box,
    Button,
    Center,
    Checkbox,
    CheckboxGroup,
    Fade,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Image,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Text,
    useToast
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from 'react-icons/fi';
import { IoMdCloseCircle } from "react-icons/io";
import { useGetActiveAccountsListQuery } from 'store/adAccounts/adAccountsApi';
import {
    useFinishUploadMutation,
    useStartUploadSessionMutation, useUploadChunkMutation,
    useUploadImageMutation,
    useUploadVideoMutation,
} from 'store/driveUpload/uploadApi';
import { truncateName } from "utils/functions";
import { adUplaodSchema } from "utils/schema";
import FileUploadStatusWidget from './FileUploadStatusWidget';

// Constants
const MAX_IMAGE_UPLOAD_SIZE_MB = 30; // Max size for images
const MAX_VIDEO_UPLOAD_SIZE_GB = 4; // Max size for videos
const MAX_IMAGE_UPLOAD_SIZE_BYTES = MAX_IMAGE_UPLOAD_SIZE_MB * 1024 * 1024;
const MAX_VIDEO_UPLOAD_SIZE_BYTES = MAX_VIDEO_UPLOAD_SIZE_GB * 1024 * 1024 * 1024;
const chunkSize = 5 * 1024 * 1024;

const UploadModal = ({ isOpen, onClose, onSuccess }) => {
    const [files, setFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState({});
    const [currentAdAccount, setCurrentAdAccount] = useState(null);
    const uploadControllers = useRef({});
    const toast = useToast()
    const [isWidgetOpen, setIsWidgetOpen] = useState(false);
    const [videoUploadCompleted, setVideoUploadCompleted] = useState(false);
    const [imageUploadCompleted, setImageUploadCompleted] = useState(false);
    const [uploadAbortControllers, setUploadAbortControllers] = useState({});

    const [uploadImage, { isLoading: isImageLoading }] = useUploadImageMutation();
    const [uploadVideo, { isLoading: isVideoLoading }] = useUploadVideoMutation();

    const { data: activeAccountsData, isFetching: isActiveAccountsFetching, isLoading: isActiveAccountsDataLoading } = useGetActiveAccountsListQuery();
    const [startUploadSession] = useStartUploadSessionMutation();
    const [uploadChunk] = useUploadChunkMutation();
    const [finishUpload] = useFinishUploadMutation();


    useEffect(() => {
        if (files?.length > 0) {
            if (imageUploadCompleted) {
                setTimeout(() => {
                    handleCloseWidget();
                    setIsWidgetOpen(false);
                }, 5000);
            }//else {
            //     const anyFailedOrCancelled = files.some(file => {
            //     const statuses = uploadStatus[file.name] || {};
            //     const accounts = Object.keys(statuses);
            //     return accounts.some(accountId => {
            //         const status = statuses[accountId] || {};
            //         return status.status === "failed" || status.status === "cancelled";
            //     });
            // });

            // if (anyFailedOrCancelled) {
            //     setTimeout(() => {
            //         handleCloseWidget();
            //         setIsWidgetOpen(false);
            //     }, 1000);
            // }}
        }
    }, [uploadStatus, imageUploadCompleted]);

    useEffect(() => {
        // console.log("Upload Status:", uploadStatus);
        //console.log('files are',files);
        if (files?.length > 0) {
            if (videoUploadCompleted) {
                setTimeout(() => {
                    handleCloseWidget();
                    setIsWidgetOpen(false);
                }, 5000);
            }//else {
            //     const anyFailedOrCancelled = files.some(file => {
            //         const statuses = uploadStatus[file.name] || {};
            //         const accounts = Object.keys(statuses);
            //         return accounts.some(accountId => {
            //             const status = statuses[accountId] || {};
            //             return status.status === "failed";
            //         });
            //     });

            //     if (anyFailedOrCancelled) {
            //         setTimeout(() => {
            //             handleCloseWidget();
            //             setIsWidgetOpen(false);
            //         }, 1000);
            //     }
            // }
        }
    }, [uploadStatus, videoUploadCompleted]);

    // Formik setup
    const uploadFormik = useFormik({
        initialValues: {
            adsAccountName: [],
            mediaType: '',
            files: [],
        },
        validationSchema: adUplaodSchema,
        onSubmit: async (values, { setSubmitting }) => {
            // Handle form submission
            if (values) {
                handleUploadAll(values.files);
                setIsWidgetOpen(true);
            }
            setSubmitting(false);
            uploadFormik.resetForm();
            onClose();
        },
    });

    const onDrop = (acceptedFiles, rejectedFiles) => {
        // Handle rejected files
        if (rejectedFiles.length > 0) {
            toast({
                title: "Upload Error",
                description: "The uploaded file exceeds the maximum size limit.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
            return;
        }

        // Check file sizes and handle invalid files
        const validFiles = [];
        for (const file of acceptedFiles) {
            if (file.type.startsWith("image/") && file.size > MAX_IMAGE_UPLOAD_SIZE_BYTES) {
                toast({
                    title: "Upload Error",
                    description: `The image ${file.name} exceeds the maximum size of 30 MB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
            } else if (file.type.startsWith("video/") && file.size > MAX_VIDEO_UPLOAD_SIZE_BYTES) {
                toast({
                    title: "Upload Error",
                    description: `The video ${file.name} exceeds the maximum size of 4 GB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
            } else {
                validFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    thumbnail: URL.createObjectURL(file),
                    progress: 0,
                    status: "pending",
                    file: file,
                });
            }
        }

        if (validFiles.length > 0) {
            setFiles(validFiles); // Replace previous files with new ones
            uploadFormik.setFieldValue('files', validFiles); // Set files in Formik
        }
    };

    const updateUploadStatus = (fileName, accountId, status, progress) => {
        setUploadStatus(prev => {
            const updatedStatus = { ...prev };
            if (!updatedStatus[fileName]) {
                updatedStatus[fileName] = {};
            }
            updatedStatus[fileName][accountId] = { status, progress };
            return updatedStatus;
        });
    };


    const handleUploadAll = async (filesToUpload) => {
        // const notifyOffline = () => {
        //     toast({
        //         title: "Offline",
        //         description: "You are offline. Some functionality may be unavailable.",
        //         status: "warning",
        //         duration: 5000,
        //         isClosable: true,
        //         position: "bottom-left",
        //     });
        // };
    
        // // Listen for offline event
        // window.addEventListener('offline', notifyOffline);

        for (const file of filesToUpload) {
            const adsAccountsArray = uploadFormik?.values?.adsAccountName.map(accountId => {
                const selectedAdAccount = activeAccountsData?.data?.find(account => account?.adsAccountId === accountId);
                return selectedAdAccount ? {
                    id: selectedAdAccount?._id,
                    adsAccountId: selectedAdAccount?.adsAccountId,
                    adsAccountName: selectedAdAccount?.adsAccountName,
                } : null;
            }).filter(account => account !== null);

            if (adsAccountsArray.length === 0) {
                toast({
                    title: "Account Error",
                    description: "Please select a valid Ads Account.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
                return;
            }

            if (file.type.startsWith("image/")) {
                console.log(`Uploading image ${file.name}...`);
                const formData = new FormData();
                formData.append('filename', file.file);
                formData.append('adsAccount', JSON.stringify(adsAccountsArray));

                try {
                    const response = await uploadImage({ payload: formData }).unwrap();
                    //console.log(`Image ${file.name} uploaded successfully.`);
                    adsAccountsArray.forEach(account => {
                        updateUploadStatus(file.name, account.adsAccountId, "done", 100);
                    });
                } catch (error) {
                    //console.error(`Error uploading image ${file.name}:`, error);
                    adsAccountsArray.forEach(account => {
                        updateUploadStatus(file.name, account.adsAccountId, "failed", 0);
                    });
                    //console.log('error is',error);
                    toast({
                        title: "Upload Error",
                        description: error?.data?.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                    });
                }
            } else if (file.type.startsWith("video/")) {

                for (const selectedAdAccount of adsAccountsArray) {
                    setCurrentAdAccount(selectedAdAccount);
                    const abortController = new AbortController();
                    const controllerKey = `${file.name}-${selectedAdAccount.adsAccountId}`;
                    setUploadAbortControllers(prev => ({
                        ...prev,
                        [controllerKey]: abortController,
                    }));

                    try {
                        const startSessionData = await startUploadSession({ file, adsAccount: selectedAdAccount.adsAccountId }).unwrap();

                        if (startSessionData?.data?.status === 'error') {
                            toast({
                                title: "Upload Error",
                                description: `${startSessionData?.data?.message}`,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                                position: "top-right",
                            });
                            setUploadStatus(prev => ({
                                ...prev,
                                [file.name]: {
                                    ...prev[file.name],
                                    [selectedAdAccount.adsAccountId]: { status: "failed", progress: 0 },
                                }
                            }));
                            return;
                        }

                        const chunks = splitFile(file);
                        const uploadState = loadUploadState(file.name) || {};
                        let allChunksUploaded = true;

                        //console.log(`Starting upload of video ${file.name}. Total chunks: ${chunks.length}`);
                        for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
                            if (abortController?.signal?.aborted) {
                                allChunksUploaded = false;
                                break; // Exit chunk upload loop
                            }
                            const { chunk, start, end } = chunks[chunkIndex];
                            const chunkId = `${start}-${end}`;

                            if (uploadState[chunkId] === 'done') {
                                //console.log(`Chunk ${chunkIndex + 1} of video ${file.name} already uploaded.`);
                                continue;
                            }

                            try {
                                if (!navigator.onLine) {
                                    toast({
                                        title: "You are offline. Some functionality may be unavailable.",
                                        status: "error",
                                        duration: 5000,
                                        isClosable: true,
                                        position: "top-right",
                                    });
                                    //console.log(`Network offline. Waiting to retry upload of chunk ${chunkIndex + 1} for ${file.name}`);
                                    await new Promise(resolve => {
                                        window.addEventListener('online', () => {
                                            toast({
                                                position: "top-right",
                                                title: "Upload resumed",
                                                status: "success",
                                                duration: 4000,
                                                isClosable: true,
                                            });
                                            //console.log(`Network online. Resuming upload of chunk ${chunkIndex + 1} for ${file.name}`);
                                            resolve();
                                        }, { once: true });
                                    });
                                }

                                const uploadResponse = await uploadChunk({
                                    upload_session_id: startSessionData?.data?.upload_session_id,
                                    start_offset: start,
                                    end_offset: end,
                                    chunk,
                                    adsAccount: selectedAdAccount.adsAccountId,
                                }).unwrap();

                                if (!uploadResponse) {
                                    //console.warn(`Upload failed for chunk ${chunkIndex + 1} of video ${file.name}`);
                                    allChunksUploaded = false;
                                    break;
                                }

                                const progress = Math.round(((chunkIndex + 1) / chunks.length) * 100);
                                //console.log(`Chunk ${chunkIndex + 1} of video ${file.name} uploaded successfully. Progress: ${progress}%`);
                                updateUploadStatus(file.name, selectedAdAccount.adsAccountId, "uploading", progress);

                                saveUploadState(file.name, chunkId, 'done');
                            } catch (uploadError) {
                                //console.error(`Error uploading chunk ${chunkIndex + 1} of video ${file.name}`, uploadError);
                                allChunksUploaded = false;

                                // Exponential backoff for retry
                                const retryDelay = Math.pow(2, chunkIndex) * 1000;
                                //console.log(`Retrying chunk ${chunkIndex + 1} of video ${file.name} in ${retryDelay / 1000} seconds.`);
                                await new Promise(resolve => setTimeout(resolve, retryDelay));
                                chunkIndex--; // Retry the same chunk
                            }
                        }

                        if (allChunksUploaded && !abortController.signal.aborted) {
                            //console.log(`All chunks uploaded for video ${file.name}. Finalizing upload...`);
                            await finishUpload({
                                upload_session_id: startSessionData?.data?.upload_session_id,
                                file,
                                video_id: startSessionData?.data?.video_id,
                                payload: {
                                    adsAccount: {
                                        id: selectedAdAccount?.id,
                                        adsAccountId: selectedAdAccount?.adsAccountId,
                                        adsAccountName: selectedAdAccount?.adsAccountName
                                    }
                                },
                            }).unwrap();
                            onSuccess();

                            //console.log(`Video ${file.name} uploaded successfully.`);
                            updateUploadStatus(file.name, selectedAdAccount.adsAccountId, "done", 100);
                            clearUploadState(file.name);
                        } else {
                            //console.error(`Failed to upload all chunks for video ${file.name}`);
                            updateUploadStatus(file.name, selectedAdAccount.adsAccountId, "failed", 0);
                        }
                    } catch (error) {
                        //console.error(`Error during video upload ${file.name}:`, error);
                        updateUploadStatus(file.name, selectedAdAccount.adsAccountId, "failed", 0);
                    }
                }
            }
        }
        setVideoUploadCompleted(true);
        setImageUploadCompleted(true);
    };


    // if (navigator.onLine) {
    //     console.log('Online here');
    //     window.addEventListener("online", (e) => {
    //         console.log("online");
    //       });    
    // }else{
    //     console.log('offline went');
    // }

    // window.addEventListener("offline", (e) => {
    //     console.log("offline");
    //   });
      


    const saveUploadState = (fileName, chunkId, status) => {
        const uploadState = JSON.parse(localStorage.getItem(`upload_state_${fileName}`)) || {};
        uploadState[chunkId] = status;
        localStorage.setItem(`upload_state_${fileName}`, JSON.stringify(uploadState));
    };

    const loadUploadState = (fileName) => {
        return JSON.parse(localStorage.getItem(`upload_state_${fileName}`)) || {};
    };

    const clearUploadState = (fileName) => {
        localStorage.removeItem(`upload_state_${fileName}`);
    };


    const splitFile = (file) => {
        const chunks = [];
        let start = 0;

        while (start < file?.file?.size) {
            const end = Math.min(start + chunkSize, file?.file?.size);
            const chunk = file?.file.slice(start, end);
            chunks.push({ start, end, chunk });
            start = end;
        }
        return chunks;
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open
    } = useDropzone({
        onDrop,
        accept: uploadFormik.values.mediaType === 'image' ? {
            "image/*": [".png", ".jpg", ".jpeg"]
        } : {
            "video/*": [".mp4", ".mov", ".avi", ".gif"]
        },
        multiple: true,
        maxFiles: 50,
        maxSize: uploadFormik.values.mediaType === 'image' ? MAX_IMAGE_UPLOAD_SIZE_BYTES : MAX_VIDEO_UPLOAD_SIZE_BYTES,
        disabled: !uploadFormik.values.mediaType || !uploadFormik.values.adsAccountName.length
    });

    const handleCloseWidget = () => {
        // Reset states when the widget closes
        setFiles([]);
        setUploadStatus({});
        uploadControllers.current = {};
    };

    const removeFile = (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        uploadFormik.setFieldValue('files', updatedFiles);
    };

    const handleCancelUpload = (fileName, accountId) => {
        //console.log('Cancelled fileName is', fileName, 'accountId is', accountId);

        const controllerKey = `${fileName}-${accountId}`;
        const controller = uploadAbortControllers[controllerKey];

        if (controller) {
            controller.abort();
            toast({
                title: "Upload Canceled",
                description: `The upload for ${fileName} has been canceled for account ${accountId}.`,
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });

            updateUploadStatus(fileName, accountId, "cancelled", 0);

            setUploadAbortControllers(prev => {
                const newControllers = { ...prev };
                delete newControllers[controllerKey];
                return newControllers;
            });
        }
    };

    const showMediaTypeError = !uploadFormik.values.mediaType && !uploadFormik.values.adsAccountName.length;
    const showAdAccountError = !uploadFormik.values.adsAccountName.length && uploadFormik.values.mediaType;

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => { onClose(); uploadFormik.resetForm(); }} size={{ base: "4xl" }}>
                <ModalOverlay />
                <ModalContent maxH="80vh">
                    <ModalHeader>
                        <Flex direction={'row'} gap={'20px'} align={'center'}>
                            <Box flex={0.8}>
                                <FormControl>
                                    <FormLabel fontWeight={'bold'}>Ad Accounts</FormLabel>
                                    <CheckboxGroup
                                        colorScheme={'orange'}
                                        id="adsAccountName"
                                        value={uploadFormik.values.adsAccountName}
                                        onChange={(selectedValues) => uploadFormik.setFieldValue('adsAccountName', selectedValues)}
                                    >
                                        <Stack spacing={2}>
                                            {activeAccountsData?.data?.map(account => (
                                                <Checkbox size={'sm'} key={account._id} value={account.adsAccountId}>
                                                    {account.adsAccountName}
                                                </Checkbox>
                                            ))}
                                        </Stack>
                                    </CheckboxGroup>
                                    {uploadFormik.touched.adsAccountName && uploadFormik.errors.adsAccountName && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>
                                            {uploadFormik.errors.adsAccountName}
                                        </Text>
                                    )}
                                </FormControl>
                            </Box>
                            <Box flex={0.2}>
                                <FormControl>
                                    <Select
                                        size={'sm'}
                                        borderRadius={'md'}
                                        id="mediaType"
                                        name="mediaType"
                                        value={uploadFormik.values.mediaType}
                                        onChange={uploadFormik.handleChange}
                                        onBlur={uploadFormik.handleBlur}
                                        isInvalid={uploadFormik.touched.mediaType && uploadFormik.errors.mediaType ? true : false}
                                    >
                                        <option value="">Add Media</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                    </Select>
                                    {uploadFormik.touched.mediaType && uploadFormik.errors.mediaType && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{uploadFormik.errors.mediaType}</Text>
                                    )}
                                </FormControl>
                            </Box>
                        </Flex>
                    </ModalHeader>
                    <ModalBody overflowY="auto" maxH="calc(80vh - 120px)">
                        <Flex direction={'column'} gap={'20px'} minH={'40vh'}>
                            <Box flex={1}>
                                <FormControl>
                                    {showMediaTypeError || showAdAccountError ? (
                                        <Text color="red.500" fontSize="sm" textAlign="center">
                                            {showMediaTypeError && !showAdAccountError && "Please select a media type and at least one ad account to upload files."}
                                            {showAdAccountError && !showMediaTypeError && "Please select at least one ad account to upload files."}
                                        </Text>
                                    ) : !files.length ? (
                                        <Center
                                            className="dropzone"
                                            {...getRootProps()}
                                            sx={{
                                                textAlign: 'center',
                                                padding: '20px 0px',
                                                width: '100%',
                                                margin: '0 auto',
                                                borderWidth: '1px',
                                                borderColor: 'gray.600',
                                                borderStyle: 'dashed',
                                                background: 'gray.50',
                                                borderRadius: 5,
                                                minHeight: '240px'
                                            }}
                                            borderColor={isDragActive ? 'gray.500' : 'gray.600'}
                                        >
                                            <Input {...getInputProps()} />
                                            <Flex flexDirection={'column'} align={'center'} justify={'center'} gap={'10px'} minW={'220px'}>
                                                <Icon as={FiUpload} fontSize="2xl" color={'gray.800'} />
                                                <Text fontSize={{ base: '10px', md: '12px' }}>
                                                    Drag 'n' drop file or <Link textDecoration={'underline'} color={'gray.800'} onClick={open} size={'xs'}>
                                                        Browse
                                                    </Link>
                                                </Text>
                                            </Flex>
                                        </Center>
                                    ) : (
                                        <Fade in={files.length > 0}>
                                            <Flex flexDirection="row" flexWrap={'wrap'} align="flex-start" gap="30px" minW="220px" pos="relative">
                                                {files.map((file, index) => (
                                                    <Box key={file.name} role="group" position="relative" w="80px" h={'80px'} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
                                                        {file?.type.startsWith("video/") ? (
                                                            <video
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                                                src={file.thumbnail}
                                                                muted
                                                            />
                                                        ) : (
                                                            <Image
                                                                src={file.thumbnail}
                                                                alt={file.name}
                                                                objectFit="cover"
                                                                width="100%"
                                                                height="100%"
                                                                borderRadius="3px"
                                                            />
                                                        )}
                                                        <Icon
                                                            top={'-10px'}
                                                            right={'-10px'}
                                                            position="absolute"
                                                            color={'red.500'}
                                                            _hover={{ color: 'gray.400' }}
                                                            as={IoMdCloseCircle}
                                                            cursor="pointer"
                                                            boxSize={4}
                                                            onClick={() => removeFile(index)}
                                                        />
                                                        <Text fontSize={'10px'}>{truncateName(file?.name)}</Text>
                                                    </Box>
                                                ))}
                                            </Flex>
                                        </Fade>
                                    )}
                                    {uploadFormik.touched.files && uploadFormik.errors.files && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>
                                            {uploadFormik.errors.files}
                                        </Text>
                                    )}
                                </FormControl>
                            </Box>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Flex gap={'20px'} justify={'center'}>
                            <Button size={'sm'} colorScheme='orange' onClick={uploadFormik.handleSubmit}>
                                Submit
                            </Button>
                            <Button size={'sm'} onClick={() => { uploadFormik.resetForm(); onClose(); setFiles([]); }}>
                                Cancel
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FileUploadStatusWidget
                isWidgetOpen={isWidgetOpen}
                files={files}
                uploadStatus={uploadStatus}
                adsAccount={currentAdAccount}
                isUploading={isImageLoading || isVideoLoading}
                onClose={() => {
                    handleCloseWidget();
                    setIsWidgetOpen(false);
                }}
                onCancel={handleCancelUpload}
            />
        </>
    );
};

export default UploadModal;

