import { Spinner} from '@chakra-ui/react';
import LeadForm from 'components/campaignForms/AdSetForms/LeadsForm';
import SalesForm from 'components/campaignForms/AdSetForms/SalesForm';
import { useGetAdsetFormQuery } from 'store/campaigns/adsetFormApi';
import { useGetcampaignByIdQuery } from 'store/campaigns/campaignsApi';

// const validationSchema = Yup.object().shape({
//     name: Yup.string().required('Adset name is required'),
//     daily_budget: Yup.number().required('Daily budget is required').positive('Daily budget must be a positive number').nullable(),
//     lifetime_budget: Yup.number().required('Lifetime budget is required').positive('Lifetime budget must be a positive number').nullable(),
//     bid_amount: Yup.number().when('bid_strategy', {
//         is: 'LOWEST_COST_WITH_BID_CAP',
//         then: schema => schema.required('Bid amount is required'),
//         otherwise: schema => schema.nullable()
//     }),
//     roas_amount: Yup.number().when('bid_strategy', {
//         is: 'LOWEST_COST_WITH_MIN_ROAS',
//         then: schema => schema.required('ROAS amount is required'),
//         otherwise: schema => schema.nullable()
//     })
// });


const AdSetUpdateForm = ({ adsetData, onClose, onSaveSuccess }) => {

    const { campaign_id : campaignId, } = adsetData || {};
    
    const { data: campaignData, error: campaignError, isLoading: isLoadingCampaign } = useGetcampaignByIdQuery({campaignId});

    const campaignType = campaignData?.objective;

    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });

    // const [updateAdset, { isLoading: isAdsetUpdating }] = useUpdateAdsetMutation();

    // const initialValues = {
    //     name: adsetName || '',
    //     daily_budget: daily_budget || '',
    //     lifetime_budget: lifetime_budget || '',
    //     bid_amount: '',
    //     roas_amount: '',
    //     bid_strategy: bid_strategy || 'LOWEST_COST_WITHOUT_CAP'
    // };

    // const handleSubmit = (values, { setSubmitting }) => {
    //     const payload = {
    //         name: values.name,
    //         daily_budget: values.daily_budget,
    //         lifetime_budget: values.lifetime_budget,
    //         ...(values.bid_strategy === 'LOWEST_COST_WITH_BID_CAP' && { bid_amount: values.bid_amount }),
    //         ...(values.bid_strategy === 'LOWEST_COST_WITH_MIN_ROAS' && { roas_amount: values.roas_amount })
    //     };

    //     updateAdset({ adsetId: id, payload })
    //         .unwrap()
    //         .then((response) => {
    //             toast({
    //                 position: "top-right",
    //                 title: "Adset updated",
    //                 status: "success",
    //                 duration: 4000,
    //                 isClosable: true,
    //             });
    //             console.log(response);
    //         })
    //         .catch((error) => {
    //             toast({
    //                 position: "top-right",
    //                 title: "Error in Adset update",
    //                 status: "error",
    //                 duration: 4000,
    //                 isClosable: true,
    //             });
    //             console.log(error);
    //         })
    //         .finally(() => {
    //             onClose();
    //             setSubmitting(false);
    //         });
    // };
    

    const renderForm = () => {
        if (campaignType === 'OUTCOME_SALES') {
            if (isLoadingSales) {
                return <Spinner size={'sm'} />;
            }
            if (dataSales.data && campaignData) {
                return (
                    <SalesForm
                        data={dataSales.data}
                        campaignData={campaignData}
                        selectedAdset={adsetData}
                        onEditModalClose={onClose}
                        onClose={onClose}
                        isEditing={true} 
                        context="adsetEdit"
                        onSaveSuccess={onSaveSuccess}
                    />
                );
            }
        } else if (campaignType === 'OUTCOME_LEADS') {
            if (isLoadingLeads) {
                return <Spinner size={'sm'} />;
            }
            if (dataLeads.data && campaignData) {
                return (
                    <LeadForm
                        data={dataLeads.data}
                        campaignData={campaignData}
                        selectedAdset={adsetData}
                        onEditModalClose={onClose}
                        onClose={onClose}
                        isEditing={true} 
                        context="adsetEdit"
                        onSaveSuccess={onSaveSuccess}
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
         {renderForm()}
        </>    
    );
};

export default AdSetUpdateForm;
