import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Flex, Text } from '@chakra-ui/react';

const TreeView = ({ tree }) => {

    return (
        <Box p={'10px'}>
            <Accordion allowMultiple>
                {tree?.adsAccounts?.map((account, index) => (
                    <AccordionItem key={index}>
                        <AccordionButton>
                            <Flex flex={1}>
                                <Text fontSize={'16px'}>Ad Account:&nbsp;</Text>
                                <Text fontSize={'16px'} fontWeight={'bold'}>{account?.adsAccount?.adsAccountName} ({account?.adsAccount?.adsAccountId})</Text>
                            </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} >
                            <Box spacing={3} px={'10px'}>
                                <Text fontSize={'16px'}> Campaign :&nbsp;{account?.campaign?.name} ({account?.campaign.campaign_id})</Text>
                                <Divider my={'10px'} />
                                <Box px={'10px'}>
                                    {account?.adsets?.map((adset, idx) => (
                                        <Text key={idx + adset?.adset_id} fontSize={'15px'}>Ad set: {adset?.name} ({adset?.adset_id})</Text>
                                    ))}
                                </Box>
                                <Divider my={'10px'} />
                                <Box px={'10px'}>
                                    {account?.ads?.map((ad, index) => (
                                        <Text key={index + ad?.ad_id} fontSize={'14px'}>Ad: {ad?.name} ({ad?.ad_id})</Text>
                                    ))}
                                </Box>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

export default TreeView;