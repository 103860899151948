import { api } from '../api'

export const uploadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchImages: builder.query({
      query: ({ currentPage, searchValue, adsAccountId }) => `/creatives/image/${adsAccountId}?page=${currentPage}&search=${searchValue}`,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['FileUpload'],
    }),
    fetchVideos: builder.query({
      query: ({ currentPage, searchValue, adsAccountId }) => `/creatives/video/${adsAccountId}?page=${currentPage}&search=${searchValue}`,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['FileUpload'],
    }),
    uploadImage: builder.mutation({
      query: ({ payload }) => ({
        url: "/creatives/image",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ['FileUpload'],
    }),
    uploadVideo: builder.mutation({
      query: ({ payload }) => ({
        url: "/creatives/video",
        method: "POST",
        body: payload,
      }),
    }),
    // Start upload session
    startUploadSession: builder.mutation({
      query: ({file,adsAccount}) => ({
        url: `/creatives/video/?upload_phase=start&file_size=${file.size}&adsAccount=${adsAccount}&file_name=${file.name}`,
        method: 'POST',
      }),
    }),
    // Upload file chunk
    uploadChunk: builder.mutation({
      query: ({ upload_session_id, start_offset, end_offset, chunk,file_size, adsAccount }) => {

        const formData = new FormData();
        formData.append("video_file_chunk", chunk);

        return{
          url: `/creatives/video/?upload_phase=transfer&adsAccount=${adsAccount}&upload_session_id=${upload_session_id}&start_offset=${start_offset}&end_offset=${end_offset}&file_size=${file_size}`,
          method: 'POST',
          body :formData,
          formData:true, 
        }
      },
    }),
    // Finish the upload
    finishUpload: builder.mutation({
      query: ({ upload_session_id,video_id,file,adsAccount,payload }) => {

        return{
          url: `/creatives/video/?upload_phase=finish&upload_session_id=${upload_session_id}&file_size=${file.size}&video_id=${video_id}&file_name=${file.name}`,
          method: 'POST',
          body: payload,
       }     
      },
    }),
  }),
})

export const {
  useFetchImagesQuery,
  useFetchVideosQuery,
  useUploadImageMutation,
  useUploadVideoMutation,
  useStartUploadSessionMutation,
  useUploadChunkMutation,
  useFinishUploadMutation,
} = uploadApi;