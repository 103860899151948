import { api } from "../api";

export const campaignApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdspressoCampaignById: builder.query({
            query: (campaignId) => ({
                url: `/adspresso/campaigns/${campaignId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdspressoCampaign']
        }),
        getAdspressoCampaigns: builder.query({
            query: ({ currentPage, searchValue }) => ({
                url: `/adspresso/campaigns?page=${currentPage}&search=${searchValue}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdspressoCampaigns']
        }),
        createAdspressoCampaign: builder.mutation({
            query: ({ payload }) => ({
                url: '/adspresso/campaigns/',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['AdspressoCampaigns'],
        }),
        runAdspressoCampaign: builder.mutation({
            query: (campaignId) => ({
                url: `/adspresso/campaigns/${campaignId}`,
                method: 'POST',
            }),
            invalidatesTags: ['AdspressoCampaigns'],
        }),
        publishAdspressoCampaign: builder.mutation({
            query: (campaignId) => ({
                url: `/adspresso/publish/${campaignId}`,
                method: 'POST',
            }),
            invalidatesTags: ['AdspressoCampaigns'],
        }),
    }),
});

export const { useCreateAdspressoCampaignMutation, useGetAdspressoCampaignByIdQuery, useGetAdspressoCampaignsQuery, useRunAdspressoCampaignMutation, usePublishAdspressoCampaignMutation } = campaignApi;


