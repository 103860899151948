import { AddIcon, CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, CheckboxGroup, Collapse, Divider, Flex, Icon, Input, Select, FormErrorMessage, Text, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiChevronUp } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { useCreateAdspressoCreativeMutation, useGetAdspressoCreativeByCampaignIdQuery, useRemoveCreativeMutation, useUpdateAdspressoAdCreativeByIdMutation } from 'store/adspresso/adspressoAdApi';
import { object, string } from "yup";

function SingleMediaCreative({ adsAccounts, adFormik, adsetsListData, campaignId, call_to_action_options, adData }) {
  const toast = useToast();
  const [isDriveOpen, setIsDriveOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [uploadPurpose, setuploadPurpose] = useState(null);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedAdSets, setSelectedAdSets] = useState({});
  const [mediaFiles, setMediaFiles] = useState([]);
  const [fileBeingEdited, setFileBeingEdited] = useState(null);
  const [mediaFilesState, setMediaFilesState] = useState({});
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [errors, setErrors] = useState({});

  const [createAdspressoAds, { isLoading: isAdsCreating, error: createError }] = useCreateAdspressoCreativeMutation();
  const { data: AdspressoCreatives } = useGetAdspressoCreativeByCampaignIdQuery(campaignId);
  const [updateAdspressocreativeAds, { isLoading: isAdspressoAdsetUpdating }] = useUpdateAdspressoAdCreativeByIdMutation({});
  const [removeCreative, removeCreativeResponse] = useRemoveCreativeMutation();

  
  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  const handleMediaTypeChange = (adAccounts, type) => {
    setSelectedAdAccounts(adAccounts);
    setMediaType(type);
    setIsDriveOpen(true);
  }

  const handleAdAccountChange = (selectedValues) => {
    const selectedAccounts = adsAccounts.filter(account => selectedValues.includes(account.adsAccountId));
    setSelectedAdAccounts(selectedAccounts);
  };

  const handleAdSetChange = (selectedValues) => {

    setSelectedAdSets(prev => {
      const updated = {};
      selectedAdAccounts.forEach(account => {
        updated[account.adsAccountId] = selectedValues;
      });
      return updated;
    });
  };

  const handleFileSelect = (files) => {

    if (files.length > 0) {
      if (fileBeingEdited) {
        // Handle update scenario
        setMediaFiles(files);
        const selectedFile = files[0];
        const payload = {
          url: selectedFile?.creativeType === 'image' ? selectedFile?.url : selectedFile?.source,
        };

        updateAdspressocreativeAds({ adcreativeId: fileBeingEdited?._id, payload }).unwrap()
          .then(response => {
            console.log('Ad creative updated successfully:', response);
          })
          .catch(error => {
            toast({
              position: "top-right",
              title: "Error updating image",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          });
      } else {
        // Handle creation scenario
        setMediaFiles(prevFiles => [...prevFiles, ...files])
      }
      setFileBeingEdited(null);
    }
  };

  const handleChangeImage = (file, adAccount) => {
    setuploadPurpose('update')
    handleMediaTypeChange([adAccount], file?.type);
    setSelectedAdAccounts([adAccount]);
    setFileBeingEdited(file);
    setSelectedCreative(file)
  };

  const truncateName = (name, maxLength = 14) => {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength) + '...';
    }
    return name;
  };


  useEffect(() => {
    const initialMediaFilesState = {};
    AdspressoCreatives?.data?.forEach(creative => {
      if (creative.adType === 'SINGLE') {

        creative.adcreatives?.forEach(file => {

          initialMediaFilesState[file._id] = {
            name: file.name || '',
            description: file.description || '',
            message: file.message || '',
            link: file.link || '',
            type: file.type || '',
            image_hash: file.image_hash || '',
            url: file.url || '',
            video_id: file.video_id || ''
          };

        });
      } else {
        // console.log('Creative is not of type SINGLE, skipping adcreatives.');
      }
      
    });
    setMediaFilesState(initialMediaFilesState);
  }, [AdspressoCreatives]);


  let schema = object().shape({
    name: string().
      max(300, 'select atleast one type'),
    description: string().
      max(300, 'select atleast one type'),
    type: string()
      .required('Type is required'),
    message: string(),
    link: string()
      .url('Please add a valid URL') // Validates that the input is a valid URL
      .when([], {
        is: () => uploadPurpose == 'create', // Link is required only when uploadPurpose is not 'update'
        then: () => string().required('Website URL is required'),
        otherwise: () => string().notRequired(), // Link is not required when uploadPurpose is 'update'
      })
  })

  const singleMediaFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: '',
      name: '',
      description: '',
      type: 'SHOP_NOW',
      applyForAll: false,
      message: '',
      mediaFiles: [],
      link: ''
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {

      //  console.log(values);

      const { name, message, description, type, link } = values;

      const adcreatives = mediaFiles?.map(file => {

        let videoId = file?.creativeType == 'video' ? { video_id: file?.creativeId } : null;
        let videoURL = file?.creativeType == 'video' ? { url: file?.source } : null;
        let imageHash = file?.creativeType == 'image' ? { image_hash: file?.creativeId } : null;
        let imageURL = file?.creativeType == 'image' ? { url: file?.url } : null;

        return (
          {
            adsAccountId: file?.adsAccount?.adsAccountId,
            message: "",
            name: "",
            description: "",
            type: "",
            link: "",
            ...videoId,
            ...videoURL,
            ...imageHash,
            ...imageURL
          }
        )
      });

      const adsAccounts = selectedAdAccounts.map(account => ({
        id: account.id,
        adsAccountId: account.adsAccountId,
        adsAccountName: account.adsAccountName
      }));

      const adsets = adsAccounts.flatMap(account => selectedAdSets[account.adsAccountId] || []);
      const uniqueAdsets = adsets.filter((item, index) => adsets.indexOf(item) === index);

      const payload = {
        adType: adFormik.values.format,
        campaign: campaignId,
        adsets: uniqueAdsets,
        message: message,
        name: name,
        description: description,
        type: type,
        link: link,
        adcreatives: adcreatives,
        adsAccounts: adsAccounts,
      };

      //console.log(payload);


      try {
        if (uploadPurpose === 'create') {

          createAdspressoAds(payload).unwrap()
            .then(response => {
              toast({
                position: "top-right",
                title: "Ad creative added",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              // console.log('Ads creative created successfully:', response);
            })
            .catch(error => {
              const errorMessage = error?.message || "An unexpected error occurred";

              toast({
                position: "top-right",
                title: "Error creating Ad creative",
                description : errorMessage,
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            });
        }
        if (uploadPurpose === 'update') {

          const newErrors = {};
          const link = mediaFilesState[selectedCreative]?.link || '';
        
          if (!link) {
            newErrors.link = 'Website URL is required.';
          }
        
          setErrors(newErrors);
        
          if (Object.keys(newErrors).length > 0) {
            return;
          }

          let videoId = mediaFilesState[selectedCreative]?.video_id ? { video_id: mediaFilesState[selectedCreative]?.video_id } : null;
          let imageHash = mediaFilesState[selectedCreative]?.image_hash ? { image_hash: mediaFilesState[selectedCreative]?.image_hash } : null;

          const payload = {
            name: mediaFilesState[selectedCreative]?.name || '',
            description: mediaFilesState[selectedCreative]?.description || '',
            message: mediaFilesState[selectedCreative]?.message || '',
            link: mediaFilesState[selectedCreative]?.link || '',
            type: mediaFilesState[selectedCreative]?.type || call_to_action_options[0]?.value || '',
            url: mediaFilesState[selectedCreative]?.url || '',
            ...videoId,
            ...imageHash
          };

          console.log(payload);


          updateAdspressocreativeAds({ adcreativeId: selectedCreative, payload }).unwrap()
            .then(response => {
              toast({
                position: "top-right",
                title: "Ad creative updated",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              //console.log('Ads creative updated successfully:', response);
            })
            .catch(error => {
              const errorMessage = error?.data?.message || "An unexpected error occurred";
              const formattedMessage = errorMessage.replace(/['"]/g, '');
            
              toast({
                position: "top-right",
                title: "Error updating Ad creative",
                description: formattedMessage,
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            });
        }
      } catch {
      } finally {
        setSelectedCreative(null);
        setuploadPurpose(null);
        setMediaType(null);
        setMediaFiles([]); // Clear the media files
        setSelectedAdAccounts([]); // Reset selected ad account
        setSelectedAdSets({}); // Clear selected ad sets
        resetForm();
      }

    },
  });

  //console.log(singleMediaFormik.values);


  const handleDeleteFile = (indexToDelete) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    singleMediaFormik.setFieldValue('mediaFiles', mediaFiles)
  }, [mediaFiles])

  const handleDeleteCreative = (creativeId) => {

    removeCreative(creativeId)
      .unwrap()
      .then(response => {
        toast({
          position: "top-right",
          title: "Ad creative deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        console.log('Ads creative deleted successfully:', response);
      })
      .catch(error => {
        toast({
          position: "top-right",
          title: "Error deleting Ad creative",
          status: "error",
          description: error?.data?.message,
          duration: 4000,
          isClosable: true,
        });
        //console.error('Failed to delete Ad creative:', error);
      });
  };

  const handleInputChange = (fileId, field, value) => {

    setMediaFilesState(prevState => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        [field]: value,
      },
    }));
  };


  const handleRemoveImage = (fileId) => {

    //payload with empty URL and image hash
    const payload = {
      url: '',
      image_hash: ''
    };

    updateAdspressocreativeAds({ adcreativeId: fileId, payload }).unwrap()
      .then(response => {
        console.log('Media updated successfully:', response);
      })
      .catch(error => {
        console.error('Failed to update media creative:', error);
      });
  };

  const uploadedCreativesList = AdspressoCreatives?.data?.map((creative, index) => {
    if (creative.adType !== 'SINGLE') {
      return null;
    }

    const hasCreatives = creative?.adcreatives?.length > 0;

    if (!hasCreatives) {
      return null; //if no associated creatives
    }

    return (
      <Box key={creative?._id + index} p={'10px'} borderWidth={1} borderRadius="md" borderColor="gray.200">
        <Text fontSize={'14px'} fontWeight={'bold'}>Ad Account: {creative?.adsAccount?.adsAccountName}</Text>
        <Flex align={'flex-start'} direction={'column'}>
          <Text fontSize={'14px'}>Adsets:</Text>
          <Flex direction={'column'} ml={'5px'}>
            {creative?.adsets?.map((adset, adsetIndex) => (
              <Text key={adsetIndex + adset._id} fontSize={'12px'}>{adset.name}</Text>
            ))}
          </Flex>
        </Flex>

        {/* Small Image Preview */}
        <Flex wrap="wrap" gap={2} direction="column" mt={'10px'}>
          {creative?.adcreatives?.map((file, fileIndex) => (
            <Box key={file?.url + fileIndex} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200">
              <Flex alignItems="center" gap={2} justify="space-between">
                <Flex alignItems="center" gap={2}>
                  {file.url ?
                    file?.image_hash?.length > 0 ? (
                      <img
                        src={file?.url}
                        alt={file?.name}
                        style={{ width: '48px', height: '36px', objectFit: 'cover', borderRadius: '3px' }}
                      />
                    ) : (
                      <video
                        src={file?.url}
                        // controls
                        style={{ width: '48px', height: '36px', objectFit: 'cover', borderRadius: '3px' }}
                      />
                    ) : (
                      <AddIcon boxSize={5} ml={2} mr={2} />
                    )}
                  <Flex direction={'column'}>
                    <Text fontSize="10px" noOfLines={1}>
                      {mediaFilesState[file?._id]?.name || file?.name}
                    </Text>

                    <Flex gap={1}>
                      {mediaFilesState[file?._id]?.description ? (
                        <Text fontSize="10px" noOfLines={1}>{truncateName(mediaFilesState[file?._id]?.description)}</Text>
                      ) : (
                        <Text fontSize="10px">Enter a description</Text>
                      )}
                    </Flex>
                  </Flex>

                </Flex>
                <Flex alignItems="center" gap={'5px'}>
                  <Tooltip label="Edit copy" fontSize="xs">
                    <Box>
                      <Icon
                        _hover={{ color: 'gray.500' }}
                        as={openAccordionId === file?._id ? FiChevronUp : MdEdit}
                        cursor="pointer"
                        onClick={() => toggleAccordion(file?._id)}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip label="Delete" fontSize="xs">
                    <Box>
                      <Icon
                        color={'red.500'}
                        _hover={{ color: 'gray.400' }}
                        as={MdDelete}
                        cursor="pointer"
                        boxSize={4}
                        onClick={() => handleDeleteCreative(file?._id)}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Flex>

              {/* Accordion to display larger image and edit options */}
              <Collapse in={openAccordionId === file?._id}>
                <Box p={'10px'}>
                  <Flex direction={'column'}>

                    {/* Larger image */}
                    <Flex position="relative" alignItems="center" gap={4} p={2} borderRadius="md" bg={'#fff'}>
                      {file?.url ? (
                        file?.image_hash?.length > 0 ? (
                          <img
                            src={file?.url}
                            alt={file?.name}
                            style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px' }}
                          />
                        ) : (
                          <video
                            src={file?.url}
                            // controls
                            style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px' }}
                          />
                        )
                      ) : (
                        <Box p={4}>
                          <Button
                            bg={'gray.50'}
                            borderWidth={1}
                            size="sm"
                            onClick={() => {
                              handleChangeImage(file, creative?.adsAccount);
                            }}
                          >
                            {file?.image_hash?.length > 0 ? 'Select Image' : 'Select Video'}
                          </Button>
                        </Box>
                      )}

                      {file.url ?
                        <Box position="absolute" top={2} right={2} cursor="pointer" zIndex={1}>
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize="12px" p="5px">
                                <Text>
                                  Remove Image
                                </Text>
                              </Box>
                            }
                            fontSize="md"
                          >
                            <CloseIcon
                              mb={2}
                              color="red.500"
                              boxSize={3}
                              pointerEvents="all"
                              onClick={() => {
                                handleRemoveImage(file?._id);
                              }}
                            />
                          </Tooltip>
                        </Box>
                        : null
                      }


                      {/* Change Image */}
                      {file.url ?
                        <Flex position="absolute" bottom={2} right={2}>
                          <Button
                            bg={'gray.50'}
                            size="xs"
                            borderWidth={1}
                            onClick={() => {
                              handleChangeImage(file, creative?.adsAccount);
                            }}
                          >
                            {file?.image_hash?.length > 0 ? 'Change Image' : 'Change Video'}
                          </Button>
                        </Flex>
                        : null
                      }
                    </Flex>

                    {/* Headline, Description, and URL Input Fields */}
                    <Box mb={'20px'} mt={'10px'}>
                      <Flex mb={4} direction="column">
                        <Flex alignItems="center" mb={2} gap={2}>
                          <Text fontSize={'14px'} fontWeight="bold">Primary text</Text>
                        </Flex>
                        <Input
                          placeholder="Tell people what your ad is about..."
                          bg="#fff"
                          name="message"
                          size={'sm'}
                          borderRadius="md"
                          value={mediaFilesState[file?._id]?.message || ''}
                          onChange={(e) => handleInputChange(file?._id, 'message', e.target.value)}
                        />
                      </Flex>

                      <Flex mb={'20px'} direction="column">
                        <Flex alignItems="center" mb={2} gap={2}>
                          <Text fontSize={'14px'} fontWeight="bold">Headline</Text>
                        </Flex>
                        <Input
                          placeholder="Tell people what your ad is about..."
                          bg="#fff"
                          name="name"
                          size={'sm'}
                          borderRadius="md"
                          value={mediaFilesState[file?._id]?.name || ''}
                          onChange={(e) => handleInputChange(file?._id, 'name', e.target.value)}
                        />
                      </Flex>

                      {/* Description Section */}
                      <Box direction="column" mb={'20px'}>
                        <Flex alignItems="center" mb={2} gap={2}>
                          <Text fontSize={'14px'} fontWeight="bold">Description</Text>
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize="12px" p="5px">
                                <Text>
                                  Add up to five link descriptions to emphasize why people should visit your
                                  website. This won't appear in all placements.
                                </Text>
                              </Box>
                            }
                            fontSize="md"
                          >
                            <InfoOutlineIcon pointerEvents="all" />
                          </Tooltip>
                        </Flex>
                        <Textarea
                          placeholder="Include additional details"
                          bg="#fff"
                          name="description"
                          size={'sm'}
                          borderRadius="md"
                          value={mediaFilesState[file?._id]?.description || ''}
                          onChange={(e) => handleInputChange(file?._id, 'description', e.target.value)}
                          rows={2}
                        />
                      </Box>


                      <Box direction="column" mb={'20px'}>

                        <Flex alignItems="center" mb={2} gap={2}>
                          <Text fontWeight="bold" fontSize={'14px'}>Call to action</Text>
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize="sm" p="5px">
                                <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                              </Box>
                            }
                            fontSize="md"
                          >
                            <InfoOutlineIcon pointerEvents="all" />
                          </Tooltip>
                        </Flex>
                        <Select
                          name="type"
                          bg={'#fff'}
                          borderRadius={'md'}
                          value={mediaFilesState[file?._id]?.type || (call_to_action_options[0]?.value || '')}
                          onChange={(e) => handleInputChange(file?._id, 'type', e.target.value)}
                          size={'sm'}
                        >
                          {call_to_action_options?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      </Box>


                      <Flex mb={4} direction="column">
                        <Flex alignItems="center" gap={2} mb={'10px'}>
                          <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize="sm" p="5px">
                                <Text>Enter the URL for the webpage you want people to visit</Text>
                              </Box>
                            }
                            fontSize="md"
                          >
                            <InfoOutlineIcon pointerEvents="all" />
                          </Tooltip>
                        </Flex>
                        <Flex direction={'column'} gap={1}>
                          <Input
                            type="url"
                            name="link"
                            bg={'#fff'}
                            borderRadius={'md'}
                            placeholder="Enter URL"
                            value={mediaFilesState[file?._id]?.link || ''}
                            onChange={(e) => handleInputChange(file?._id, 'link', e.target.value)}
                            size={'sm'}
                          />
                          {errors.link && (
                            <Text fontSize={'12px'} color={"red.500"}>{errors.link}</Text>
                          )}
                        </Flex>
                      </Flex>

                      {/* Call to action Section */}
                      {/* <Box direction="column" mb={2}>

                        <Flex alignItems="center" mb={2} gap={2}>
                          <Text fontWeight="bold" fontSize={'14px'}>Call to action</Text>
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize="sm" p="5px">
                                <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                              </Box>
                            }
                            fontSize="md"
                          >
                            <InfoOutlineIcon pointerEvents="all" />
                          </Tooltip>
                        </Flex>
                        <Select
                          name="type"
                          bg={'#fff'}
                          borderRadius={'md'}
                          value={mediaFilesState[file?._id]?.type || ''}
                          onChange={(e) => handleInputChange(file?._id, 'type', e.target.value)}
                          size={'sm'}
                        >
                          {call_to_action_options?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      </Box>

                      {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
                        <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.mediaFiles}</Text>
                      ) : null} */}

                      {/* <Box my={'20px'}>
                        <Flex alignItems="center" gap={2} mb={'10px'}>
                          <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
                          <Tooltip
                            borderRadius={5}
                            label={
                              <Box fontSize="sm" p="5px">
                                <Text>Enter the URL for the webpage you want people to visit</Text>
                              </Box>
                            }
                            fontSize="md"
                          >
                            <InfoOutlineIcon pointerEvents="all" />
                          </Tooltip>
                        </Flex>
                        <Input
                          type="url"
                          name="link"
                          bg={'#fff'}
                          borderRadius={'md'}
                          placeholder="Enter URL"
                          value={mediaFilesState[file?._id]?.link || ''}
                          onChange={(e) => handleInputChange(file?._id, 'link', e.target.value)}
                          size={'sm'}
                        />
                        {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
                          <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
                        ) : null}
                      </Box> */}


                      {/* Save Button */}
                      <Flex justifyContent="flex-start" mt={'10px'}>
                        <Button
                          colorScheme="orange"
                          onClick={() => {

                            setuploadPurpose('update')
                            setSelectedCreative(file._id)
                            singleMediaFormik.handleSubmit()
                          }}
                          isLoading={isAdsCreating || isAdspressoAdsetUpdating}
                          size={'sm'}
                          isDisabled={!adData?.name}
                        // isDisabled={!singleMediaFormik.values.mediaFiles.length > 0}
                        >
                          Save
                        </Button>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Collapse>
            </Box>
          ))}
        </Flex>
      </Box>
    );
  });

  // console.log(singleMediaFormik.errors);

  return (
    <>
      <DriveModal
        isDriveModalOpen={isDriveOpen}
        mediaType={mediaType}
        onClose={() => {
          setIsDriveOpen(false);
          setMediaType(null);
          setFileBeingEdited(null);
        }}
        mediaFormat={adFormik?.values?.format}
        onSubmit={handleFileSelect}
        selectedAdAccounts={selectedAdAccounts}
        uploadPurpose={uploadPurpose}
      />

      <Flex flex="3" direction="column" gap={2}>
        <Flex direction="column" >
          {/* Ad Account Select */}
          <Flex direction={'column'} gap={'10px'} mb={'10px'}>
            <Text fontSize={'14px'}>Select Ad accounts</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={selectedAdAccounts.map(account => account.adsAccountId)}
              onChange={handleAdAccountChange}
            >
              <Flex
                flexWrap={'wrap'}
                gap="10px"
              >
                {adsAccounts?.map(account => (
                  <Box key={account.adsAccountId} px={'5px'} py={'2px'} width={'calc(50% - 5px)'}>
                    <Checkbox value={account.adsAccountId}>
                      <Text maxWidth="150px" fontSize={'14px'}>
                        {account.adsAccountName.length > 20
                          ? `${account.adsAccountName.substring(0, 20)}...`
                          : account.adsAccountName}
                      </Text>
                    </Checkbox>
                  </Box>
                ))}
              </Flex>
            </CheckboxGroup>
          </Flex>


          {/* Ad Set Select */}
          <Flex direction="column" gap={'5px'} mb={'20px'}>
            <Text fontSize={'14px'}>Select Ad sets</Text>
            <CheckboxGroup
              colorScheme="orange"
              value={Array.from(new Set(Object.values(selectedAdSets).flat()))}
              onChange={handleAdSetChange}
              isDisabled={!selectedAdAccounts.length > 0}
            >
              <Flex
                flexWrap={'wrap'}
                gap={'10px'}
              >
                {adsetsListData?.data?.map((adSet, index) => (
                  <Box key={`${adSet._id}-${index}`} px={'5px'} py={'2px'} width={'calc(50% - 5px)'}>
                    <Checkbox value={adSet._id} display={'flex'} flex={1}>
                      <Text fontSize={'14px'}>{adSet?.name}</Text>
                    </Checkbox>
                  </Box>
                ))}
              </Flex>
            </CheckboxGroup>
          </Flex>

          {/* Single Select component for each adAccount */}
          <Flex direction="column" flexShrink={0} mt={'10px'}>
            <Select
              placeholder={singleMediaFormik?.values?.format === 'CAROUSEL' ? "Add cards" : "Add media"}
              width={'150px'}
              bg="#fff"
              name="mediaOption"
              borderRadius={'md'}
              value={mediaType || ""}
              onChange={(e) => {
                setuploadPurpose('create');
                handleMediaTypeChange(selectedAdAccounts, e.target.value);
              }}
              size="sm"
              mb={2}
              isDisabled={Object.keys(selectedAdSets)?.length === 0 || !selectedAdAccounts?.length > 0}
            >
              <option value="image">{singleMediaFormik?.values?.format === 'CAROUSEL' ? "Add image cards" : "Add image"}</option>
              <option value="video">{singleMediaFormik?.values?.format === 'CAROUSEL' ? "Add video cards" : "Add video"}</option>
            </Select>
          </Flex>

          {/*  Media Previews */}
          {mediaFiles?.length > 0 && (
            <Box mt={'10px'}>
              <Flex wrap="wrap" gap={'10px'}>
                {mediaFiles?.map((file, index) => {
                  return (
                    <Box key={index} p={1} borderWidth={1} borderRadius="md" borderColor="gray.200">
                      <Box width={'80px'}>
                        {file?.creativeType === 'image' ? (
                          <img src={file?.url} alt={file?.name} style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }} />
                        ) : (
                          <video src={file?.source} style={{ width: '80px', height: '60px', objectFit: 'cover', borderRadius: '3px' }} />
                        )}
                        <Text fontSize="10px" noOfLines={1}>{truncateName(file?.name)}</Text>
                        <Text fontSize="10px" fontWeight={'bold'} noOfLines={1}>{truncateName(file?.adsAccount?.adsAccountName)}</Text>
                      </Box>
                      <Flex mt={'5px'} gap={'5px'} align={'center'} justify={'flex-end'}>
                        <Tooltip label="Delete" fontSize="xs">
                          <Box height={'20px'}>
                            <Icon
                              color={'red.500'}
                              _hover={{ color: 'gray.400' }}
                              as={MdDelete}
                              cursor="pointer"
                              fontSize={'18px'}
                              onClick={() => handleDeleteFile(index)}
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Box>
                  )
                })}
              </Flex>
            </Box>
          )}
        </Flex>

        <Divider mt={'10px'} />

        <Flex justifyContent={'space-between'}>
          {/* <Text fontWeight="bold">Ad copy</Text> */}
          {/* <Checkbox
            colorScheme="orange"
            isChecked={singleMediaFormik.values.applyForAll}
            onChange={(e) => singleMediaFormik.setFieldValue('applyForAll', e.target.checked)}
            size={'sm'}
          >
            Apply for all
          </Checkbox> */}
        </Flex>

        {/* Primary Text Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Primary text</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    The primary text for your ad appears in most placements, though the position
                    varies. You can use @ to tag Facebook profiles or Pages. We recommend 125
                    characters or less.
                  </Text>
                </Box>
              }
              fontSize="md">
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            size={'sm'}
            placeholder="Tell people what your ad is about..."
            bg="#fff"
            name="message"
            value={singleMediaFormik.values.message}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            borderRadius={'md'}
          />

          {singleMediaFormik.touched.message && singleMediaFormik.errors.message ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.message}</Text>
          ) : null}
        </Box>

        {/* Headline Section */}
        <Box direction="column" mb={4}>
          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Headline</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    Add up to five brief headlines to let people know what your ad is about. Each
                    headline can have a maximum of 255 characters. Headlines won't appear in all
                    placements.
                  </Text>
                </Box>
              }
              fontSize="md">
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Input
            size={'sm'}
            placeholder="Tell people what your ad is about..."
            bg="#fff"
            borderRadius={'md'}
            name="name"
            value={singleMediaFormik.values.name}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
          />

          {singleMediaFormik.touched.name && singleMediaFormik.errors.name ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.name}</Text>
          ) : null}
        </Box>

        {/* Description Section */}
        <Box direction="column" mb={2}>
          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Description</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>
                    Add up to five link descriptions to emphasise why people should visit your
                    website. This won't appear in all placements.
                  </Text>
                </Box>
              }
              fontSize="md">
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Textarea
            size={'sm'}
            placeholder="Include additional details"
            bg="#fff"
            borderRadius={'md'}
            name="description"
            value={singleMediaFormik.values.description}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            rows={3}
          />
          {singleMediaFormik.touched.description && singleMediaFormik.errors.description ? (
            <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.description}</Text>
          ) : null}
        </Box>

        {/* Call to action Section */}
        <Box direction="column">

          <Flex alignItems="center" mb={2} gap={2}>
            <Text fontWeight="bold" fontSize={'14px'}>Call to action</Text>
            <Tooltip
              borderRadius={5}
              label={
                <Box fontSize="sm" p="5px">
                  <Text>Show a button or link on your ad that represents the action you want people to take.</Text>
                </Box>
              }
              fontSize="md"
            >
              <InfoOutlineIcon pointerEvents="all" />
            </Tooltip>
          </Flex>
          <Select
            name="type"
            bg={'#fff'}
            borderRadius={'md'}
            value={singleMediaFormik.values.type}
            onChange={singleMediaFormik.handleChange}
            onBlur={singleMediaFormik.handleBlur}
            size={'sm'}
          >
            {call_to_action_options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {singleMediaFormik.touched.type && singleMediaFormik.errors.type ? (
            <Text color="red.500">{singleMediaFormik.errors.type}</Text>
          ) : null}
        </Box>

        {singleMediaFormik.touched.mediaType && singleMediaFormik.errors.mediaFiles ? (
          <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.mediaFiles}</Text>
        ) : null}

        <Flex alignItems="center" mt={'10px'} gap={2}>
          <Text fontWeight="bold" fontSize={'14px'}>Website URL</Text>
          <Tooltip
            borderRadius={5}
            label={
              <Box fontSize="sm" p="5px">
                <Text>Enter the URL for the webpage you want people to visit</Text>
              </Box>
            }
            fontSize="md"
          >
            <InfoOutlineIcon pointerEvents="all" />
          </Tooltip>
        </Flex>
        <Input
          type="url"
          name="link"
          bg={'#fff'}
          borderRadius={'md'}
          placeholder="Enter URL"
          value={singleMediaFormik.values.link}
          onChange={singleMediaFormik.handleChange}
          onBlur={singleMediaFormik.handleBlur}
          size={'sm'}
        />
        {singleMediaFormik.touched.link && singleMediaFormik.errors.link ? (
          <Text color="red.500" fontSize={'12px'}>{singleMediaFormik.errors.link}</Text>
        ) : null}


        {/* Save Button */}
        <Flex justifyContent="flex-start" mt={'10px'}>
          <Button
            colorScheme="orange"
            onClick={() => singleMediaFormik.handleSubmit()}
            isLoading={isAdsCreating || isAdspressoAdsetUpdating}
            size={'sm'}
            isDisabled={!singleMediaFormik.values.mediaFiles.length > 0 || !singleMediaFormik.values.message}
          >
            Save
          </Button>
        </Flex>

        {uploadedCreativesList?.length > 0 ? (
          <>
            <Divider my={'10px'} />
            <Flex direction="column" gap={2} >
              {/* <Text fontWeight={'bold'} mb={'10px'}>Uploaded creatives</Text> */}
              {uploadedCreativesList}
            </Flex>
          </>
        ) : null}

      </Flex>
    </>
  );
}

export default SingleMediaCreative;
