import { AddIcon } from '@chakra-ui/icons';
import {
    Badge,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Table, TableContainer,
    Tabs,
    Tbody, Td,
    Text,
    Textarea,
    Tooltip,
    Tr,
    useDisclosure,
    useToast,
    Checkbox
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';

import AppTable from 'components/AppTable';
import CircleIcon from 'components/CircleIcon';
import FileUploadBox from 'components/FileUploadBox';
import LightBox from "components/LightBox";
import MultiFileUploadButton from "components/MultiFileUploadButton";
import SelectColumnFilter from 'components/SelectColumnFilter';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useFormik } from 'formik';
import { FiEye } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useAddNewAdAccountMutation, useGetAccountsQuery, useReplyToAdAccountRequestMutation, useUploadAdAccountFileMutation, usePrefetch, useGetAllAccountsQuery, useGetActiveAccountsQuery } from 'store/adAccounts/adAccountsApi';
import { useGetAllCompaniesQuery } from 'store/companies/companiesApi';
import timezones from "timezones.json";
import { adAccountRequestSchema, replyToRequestSchema } from 'utils/schema';
import { useGetTopupByAdAccountQuery } from 'store/topUp/topUpApi';
import AppTableWithPagination from 'components/AppTableWithPagination';

function AccountListForClient() {
    const btnRef = React.useRef()
    const toast = useToast();
    const userInfo = useSelector((state) => state.auth?.userInfo);
    const { _id: clientId } = userInfo;
    const [replyFiles, setreplyfiles] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [files, setfiles] = useState([]);
    const [activeRequest, setactiveRequest] = useState(null);
    const [selectedCompanyAccountTypes, setselectedCompanyAccountTypes] = useState([]);

    const {
        isOpen: isDrawerOpen,
        onOpen: onDrawerOpen,
        onClose: onDrawerClose
    } = useDisclosure();

    const {
        isOpen: isDetailsModalOpen,
        onOpen: onDetailsModalOpen,
        onClose: onDetailsModalClose
    } = useDisclosure();

    let statusBadgeColor = 'gray';

    if (activeRequest?.status === 'pending') {
        statusBadgeColor = 'yellow';
    } else if (activeRequest?.status === 'created' || activeRequest?.status === 'closed') {
        statusBadgeColor = 'gray';
    } else if (activeRequest?.status === 'open') {
        statusBadgeColor = 'green';
    }

    const scrollBoxRef = useRef();
    const scrollToBottom = () => {
        scrollBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    useEffect(() => {
        if (activeRequest) {
            setTimeout(() => {
                scrollToBottom();
            }, 10);
        }
    }, [activeRequest]);


    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setsearchValue] = useState('');

    const [currentPageForActiveAccounts, setCurrentPageForActiveAccounts] = useState(1);
    const [searchValueForActiveAccounts, setsearchValueForActiveAccounts] = useState('');

    /* QUERIES */

    const { data: adAccountsData, error, isFetching, isLoading: isAccountsDataLoading, refetch } = useGetAccountsQuery({ currentPage, searchValue }, {
        pollingInterval: 10000,
        skipPollingIfUnfocused: true,
    });

    const { data: activeAccountsData, isFetching: isActiveAccountsFetching, isLoading: isActiveAccountsDataLoading } = useGetActiveAccountsQuery({ currentPage: currentPageForActiveAccounts, searchValue: searchValueForActiveAccounts }, {
        pollingInterval: 10000,
        skipPollingIfUnfocused: true,
    });


    //console.log({ activeAccountsData });

    const {
        data: companies,
        error: getCompaniesError,
        isLoading: isCompaniesDataLoading
    } = useGetAllCompaniesQuery();

    const [addNewAdAccountRequest, newAccountResponse] = useAddNewAdAccountMutation();
    const [uploadAdAccountFile, uploadResponse] = useUploadAdAccountFileMutation()
    const [replyToAdAccountRequest, replyResponse] = useReplyToAdAccountRequestMutation();


    const [activeAccounts, setactiveAccounts] = useState([])

    useEffect(() => {
        if (adAccountsData) {
            let filteredAccounts = adAccountsData?.data?.filter((acc, index) => acc.accountStatus && acc.accountStatus == 1 && acc.status == 'created');
            //console.log({ filteredAccounts });
            setactiveAccounts(filteredAccounts)
        }
    }, [adAccountsData])


    useEffect(() => {
        if (searchValue != '') {
            setCurrentPage(1);
        }
    }, [currentPage, searchValue])

    const getCompanyDetails = (name) => {
        const details = companies?.data?.filter(({ companyName }, index) => companyName == name)[0]?.commission
        // console.log(details);
        if (Object.keys(details)?.length > 0) {
            setselectedCompanyAccountTypes(Object.keys(details))
        }
    }

    //console.log({ adAccountsData });


    const updateFileList = (name, value) => {
        const newArray = [...files];
        const objectToUpdate = newArray.find(obj => obj.name === name);

        if (objectToUpdate) {
            objectToUpdate.value = value;
        } else {
            // Object not found, create a new object and add it to the array
            const newObject = { name, value };
            newArray.push(newObject);
        }

        setfiles(newArray);
    };

    const getFileSource = (name) => {
        return files?.filter((item, index) => item.name == name).length
            ? files?.filter((item, index) => item.name == name)[0].value
            : ''
    }

    const removeFileSource = (name) => {
        const newFiles = files?.filter(obj => obj.name !== name);
        setfiles(newFiles);
    }

    useEffect(() => {
        if (uploadResponse?.isSuccess) {
            const { name, value } = uploadResponse?.data?.data;

            updateFileList(name, value)
            toast({
                position: "top-right",
                title: "File uploaded",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
        if (uploadResponse.status == "rejected") {

            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [uploadResponse]);

    useEffect(() => {
        if (newAccountResponse?.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 8000,
                isClosable: true,
            });
        }
        if (newAccountResponse?.isSuccess) {
            toast({
                position: "top-right",
                title: "Request placed",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [newAccountResponse]);

    useEffect(() => {
        if (replyResponse.status == "rejected") {
            toast({
                position: "top-right",
                title: "Error",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
        if (replyResponse?.data) {
            const { data: { _id } } = replyResponse.data;
            if (activeRequest?._id == _id) {
                setactiveRequest(replyResponse?.data?.data)
            }
            toast({
                position: "top-right",
                title: "Reply sent",
                status: "success",
                duration: 4000,
                isClosable: true,
            });
        }
    }, [replyResponse]);


    const RequestColumns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'verified BmId',
                accessor: 'verifiedBmId',
                Filter: '',
                filter: ''
            },
            {
                Header: 'Account Type',
                accessor: 'accountType',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {

                    let accountTypeCopy = original?.accountType == 'cc' ? 'Credit Card' : 'Credit Line';
                    return (
                        <Text fontSize={'14px'}>{accountTypeCopy}</Text>
                    )
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    let badgeColor = 'gray';
                    if (original.status == 'pending') {
                        badgeColor = 'yellow'
                    } else if (original.status == 'open') {
                        badgeColor = 'green'
                    }
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={1}
                                colorScheme={badgeColor}
                            >
                                {original.status}
                            </Badge>
                            {original.status != 'created' ? (
                                <>
                                    {original.adminRead ? <CircleIcon boxSize={2} color='green.500' /> : ""}
                                </>
                            ) : null}
                        </>
                    )
                }

            },
            {
                Header: "",
                accessor: "action",
                Filter: "",
                filter: "",
                disableSortBy: true,
                Cell: ({ row: { original } }) => (
                    <Flex justifyContent={"end"} alignItems={"center"} gap={"20px"}>
                        <Tooltip label='View' fontSize='xs' >
                            <Box>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEye}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        setactiveRequest(original);
                                        onDrawerOpen();
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Flex>
                ),
            },

        ],
        []
    )

    const AccountColumns = useMemo(
        () => [

            {
                Header: 'ID',
                accessor: 'adsAccountNumber',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{'#' + original.adsAccountNumber}</Text>)
            },
            {
                Header: 'Ad Account Id',
                accessor: 'adsAccountId',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountId}</Text>)
            },
            {
                Header: 'Ad Account Name',
                accessor: 'adsAccountName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.adsAccountName}</Text>)
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'}>{original.company?.name}</Text>)
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text textAlign={'right'} fontSize={'14px'}>{original.currency}</Text>)
            },
            {
                Header: 'Top up',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);

                    // console.log({ topupData });
                    return (
                        <>

                            {topupData?.data ? (
                                <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup) + Number(original.initialBalance))?.toFixed(2)}</Text>
                            ) :
                                (
                                    <Text fontSize={'14px'} textAlign={'right'}>{Number(original.initialBalance)?.toFixed(2)}</Text>
                                )
                            }
                        </>
                    )
                }
            },
            {
                Header: 'Commission',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);

                    //console.log({ topupData });
                    return (
                        <>
                            {topupData?.data ? (
                                <Text fontSize={'14px'} textAlign={'right'}>{Number(topupData?.data?.totalCommission)?.toFixed(2)}</Text>
                            ) : null}
                        </>
                    )
                }
            },
            {
                Header: 'amount Spent',
                accessor: 'amountSpent',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => (<Text fontSize={'14px'} textAlign={'right'}>{Number(original.amountSpent)?.toFixed(2)}</Text>)
            },
            {
                Header: 'Balance',
                accessor: '',
                Filter: '',
                filter: '',
                Cell: ({ row: { original } }) => {
                    //console.log(original);
                    const { _id } = original;
                    const { data: topupData } = useGetTopupByAdAccountQuery(_id);

                    //console.log({ topupData });
                    return (
                        <>
                            {topupData?.data ? (
                                <Text fontSize={'14px'} textAlign={'right'}>{(Number(topupData?.data?.totalTopup) + Number(original.initialBalance) - Number(original.amountSpent))?.toFixed(2)}</Text>
                            ) : (
                                <Text fontSize={'14px'} textAlign={'right'}>{(Number(original.initialBalance) - Number(original.amountSpent))?.toFixed(2)}</Text>
                            )}
                        </>
                    )
                }
            },

            {
                Header: 'Account status',
                accessor: 'accountStatus',
                Filter: '',
                filter: '',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    // console.log(original);
                    return (
                        <>
                            <Badge
                                fontSize={"10px"}
                                marginRight={2}
                                colorScheme={original.accountStatus == 1 ? 'green' : 'gray'}
                            >
                                {original.accountStatus == 1 ? 'Active' : 'In active'}
                            </Badge>
                        </>
                    )
                }
            },



        ],
        []
    )

    const companyOptions = companies?.data?.filter((item, index) => item.clientId?.id == clientId && item.status).map(({ _id, companyName }, index) => (
        <option value={companyName} key={_id}>{companyName}</option>
    ))


    //console.log(activeRequest);


    const formik = useFormik({
        initialValues: {
            companyName: '',
            description: '',
            landingPage: '',
            creativeDriveLinks: '',
            verifiedBmId: '',
            screenshotOfBusinessInfo: '',
            accountTimezone: '',
            accountType: '',
            currency: '',
            confirmationCheck: false
        },
        validationSchema: adAccountRequestSchema,
        onSubmit: async (values) => {
            //console.log(values);
            const landingPageUrl = getFileSource('landingPage')
            const screenshotOfBusinessInfoUrl = getFileSource('screenshotOfBusinessInfo')

            const {
                companyName: companyTitle,
                creativeDriveLinks,
                verifiedBmId,
                accountTimezone,
                accountType,
                currency,
                description,
                landingPage
            } = values;

            const companyDetails = companies?.data?.filter(({ companyName }, index) => companyName == companyTitle)[0];
            const updatedAccountType = accountType == 'Credit Card' ? 'cc' : 'cl'

            const payload = {
                company: {
                    id: companyDetails?._id,
                    name: companyDetails?.companyName,
                },
                creativeDriveLinks,
                landingPage,
                verifiedBmId,
                screenshotOfBusinessInfo: screenshotOfBusinessInfoUrl,
                accountTimezone,
                accountType: updatedAccountType,
                currency,
                description
            }

            //console.log({ payload });

            if (values) {
                await addNewAdAccountRequest(payload);
                setfiles([])
            }
            formik.resetForm();
            onClose();
        },
    });

    const replyForm = useFormik({
        initialValues: {
            content: '',
            attachments: '',
        },
        validationSchema: replyToRequestSchema,
        onSubmit: async (values) => {
            const { content } = values
            const attachments_ = replyFiles?.map((file, index) => file.value)

            const payload = {
                content,
                attachments: attachments_,
                checked: true
            }

            // //console.log({ payload });

            if (values) {
                await replyToAdAccountRequest({ requestId: activeRequest?._id, payload });

            }
            replyForm.resetForm();
            setreplyfiles([])
        },
    });

    const handleFileUpload = (filename, acceptedFiles) => {
        acceptedFiles.map((file, index) => {
            formik.setFieldValue(filename, file)
            const reader = new FileReader();
            reader.onload = async function (e) {
                const formData = new FormData();
                if (filename == 'replyAttachments') {
                    formData.append('attachments', file)
                } else {
                    formData.append(filename, file)
                }
                //console.log({ filename, file });
                await uploadAdAccountFile(formData)
                    .unwrap()
                    .then((payload) => {
                        // //console.log('fulfilled', payload)
                        const { name, value } = payload?.data;
                        if (filename == 'replyAttachments') {
                            setreplyfiles(files => [...files, { name: 'replyAttachments', value }]);
                        }
                    })
                    .catch((error) => {
                        //console.log(error);
                    })
            };
            reader.readAsDataURL(file);
            return file;
        });
    }

    useEffect(() => {
        if (formik.values.accountType == 'Credit Line') {
            formik.setFieldValue('currency', 'INR')
        }
    }, [formik.values.accountType])


    useEffect(() => {
        const hasSingleType = selectedCompanyAccountTypes?.length == 1

        if (hasSingleType) {
            selectedCompanyAccountTypes.includes('cc')
                ? formik.setFieldValue('accountType', 'Credit Card')
                : formik.setFieldValue('accountType', 'Credit Line')
        }
    }, [selectedCompanyAccountTypes])


    const messageHistory = activeRequest && activeRequest.messages?.map(({ content, author: { name, id }, createdAt, attachments }, index) => {

        return (
            <Box mb={'10px'} key={'request' + index}>
                <Box
                    ml={id == clientId ? '20%' : '0'}
                    mr={id != clientId ? '20%' : '0'}
                    boxShadow='sm'
                >
                    <Box
                        background={id == clientId ? 'blue.50' : 'white'}
                        p={'5px 10px'} borderRadius={5} borderColor={'gray.200'} borderWidth={1}>
                        <Text fontSize={'sm'} color={"gray.600"}>{content}</Text>
                        <Flex gap={'5px'}>
                            {attachments?.length > 0 && attachments?.map((file, i) => (
                                <LightBox fileSource={file} key={'attach_' + i} />
                            ))}
                        </Flex>
                        <Text textAlign={'right'} mt={'5px'} fontSize={'9px'} color={'gray.600'}>{moment(createdAt).format("DD-MM-YYYY hh:mm A") + " • " + name}</Text>
                    </Box>
                </Box>
            </Box>
        )
    })

    return (
        <>
            {/* REQUEST DETAILS MODAL */}

            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isDetailsModalOpen}
                onClose={() => {
                    onDetailsModalClose();
                }}
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={"center"}>Request details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer>
                            <Table variant='simple' size={"sm"}>
                                <Tbody whiteSpace={'break-spaces'}>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Ads Account Name
                                        </Td>
                                        <Td>{activeRequest?.adsAccountName}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Ads Account ID
                                        </Td>
                                        <Td>{activeRequest?.adsAccountId}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Ads Account Type
                                        </Td>
                                        <Td>{activeRequest?.accountType == 'cc' ? 'Credit Card' : 'Credit Line'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Client
                                        </Td>
                                        <Td>{activeRequest?.clientId?.name}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Creative Drive Links
                                        </Td>
                                        <Td>{activeRequest?.creativeDriveLinks}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Currency
                                        </Td>
                                        <Td>{activeRequest?.currency}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Account Timezone
                                        </Td>
                                        <Td>{activeRequest?.accountTimezone}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Company Name
                                        </Td>
                                        <Td>{activeRequest?.company?.name}</Td>
                                    </Tr>

                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Description
                                        </Td>
                                        <Td whiteSpace={'break-spaces'}>{activeRequest?.description}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Status
                                        </Td>
                                        <Td>{activeRequest?.status}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Landing Page
                                        </Td>
                                        <Td>{activeRequest?.landingPage}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Screenshot of Business Info
                                        </Td>
                                        <Td><LightBox fileSource={activeRequest?.screenshotOfBusinessInfo} /></Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Created Date
                                        </Td>
                                        <Td>{moment(activeRequest?.createDate).format("DD-MM-YYYY hh:mm A")}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td w={"240px"} color='gray.500'>
                                            Updated Date
                                        </Td>
                                        <Td>{moment(activeRequest?.updatedDate).format("DD-MM-YYYY hh:mm A")}</Td>
                                    </Tr>


                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal >

            <Drawer
                isOpen={isDrawerOpen}
                placement='right'
                onClose={() => {
                    onDrawerClose();
                    setactiveRequest(null)
                }}
                finalFocusRef={btnRef}
                size={'md'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Flex align={'center'}>
                            <Text>Request History</Text>
                            <Badge ml={'10px'} fontSize={'10px'} colorScheme={statusBadgeColor}>{activeRequest?.status}</Badge>
                        </Flex>
                        <Button colorScheme='gray' variant={'outline'} size={'xs'} onClick={() => onDetailsModalOpen()}>View details</Button>
                    </DrawerHeader>


                    <DrawerBody background={'gray.50'} borderWidth={1} borderColor={'gray.300'} borderLeftWidth={0} borderRightWidth={0}>
                        <Box p={'10px'} ref={scrollBoxRef}>
                            {messageHistory}
                        </Box>
                    </DrawerBody>

                    {activeRequest?.status != 'created' ? (
                        <DrawerFooter>
                            <Box flex={1}>
                                <>
                                    <Box gap={'10px'}>
                                        <Box flex={1} mb={'10px'}>
                                            <Textarea
                                                size='md'
                                                name='content'
                                                placeholder='Message'
                                                onChange={replyForm.handleChange}
                                                onBlur={replyForm.handleBlur}
                                                value={replyForm.values.content}
                                                isInvalid={replyForm.touched.content && replyForm.errors.content ? true : false}
                                            />
                                            {replyForm.touched.content && replyForm.errors.content && (
                                                <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{replyForm.errors.content}</Text>
                                            )}
                                        </Box>

                                        <Flex flex={1} justify={'space-between'}>
                                            <MultiFileUploadButton
                                                fileSource={replyFiles}
                                                fileKey='replyAttachments'
                                                onFilesSelected={handleFileUpload}
                                                onRemoveClick={(index) => removeFileSource('replyAttachments', index)}
                                                isDisabled={!replyForm.values.content}
                                            />

                                            {replyForm.touched.attachments && replyForm.errors.attachments && (
                                                <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                                    {replyForm.errors.attachments}
                                                </Text>
                                            )}
                                            <Button
                                                size={'sm'}
                                                onClick={replyForm.handleSubmit}
                                                isDisabled={!replyForm.values.content || uploadResponse.isLoading}
                                            >Reply</Button>
                                        </Flex>
                                    </Box>
                                </>
                            </Box>
                        </DrawerFooter>
                    ) : null}

                </DrawerContent>
            </Drawer>

            {/* REQUEST MODAL */}

            <Modal scrollBehavior={'inside'} size={'2xl'} isCentered={true} isOpen={isOpen} onClose={() => { onClose(); formik.resetForm(); }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign={'center'}>Place a Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl display={'flex'} flexDirection={'column'} gap={'20px'}>
                            <Box flex={1}>
                                <Select
                                    flex={1}
                                    name="companyName"
                                    placeholder='Company Name*'
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (!!e.target.value) {
                                            //console.log(e.target.value);
                                            getCompanyDetails(e.target.value);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.companyName}
                                    isInvalid={formik.touched.companyName && formik.errors.companyName ? true : false}
                                >
                                    {companies && companyOptions}
                                </Select>
                                {formik.touched.companyName && formik.errors.companyName && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.companyName}</Text>
                                )}
                            </Box>

                            <Flex gap={'20px'}>
                                <Box flex={1}>
                                    <Select
                                        flex={1}
                                        name="accountType"
                                        placeholder='Account Type*'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.accountType}
                                        isInvalid={formik.touched.accountType && formik.errors.accountType ? true : false}
                                        isDisabled={!formik.values.companyName}
                                    >
                                        {selectedCompanyAccountTypes.includes('cc') && (
                                            <option value="Credit Card" >Credit Card</option>
                                        )}
                                        {selectedCompanyAccountTypes.includes('cl') && (
                                            <option value="Credit Line">Credit Line</option>
                                        )}
                                    </Select>
                                    {formik.touched.accountType && formik.errors.accountType && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.accountType}</Text>
                                    )}
                                </Box>
                                <Box flex={1}>
                                    <Select
                                        flex={1}
                                        name="currency"
                                        placeholder='Select Currency*'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.currency}
                                        isInvalid={formik.touched.currency && formik.errors.currency ? true : false}
                                    >
                                        {
                                            formik.values.accountType == 'Credit Card' ?
                                                <>
                                                    <option value="USD">USD</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="CAD">CAD</option>
                                                    <option value="AUD">AUD</option>
                                                    <option value="GBP">GBP</option>
                                                    <option value="SGD">SGD</option>

                                                </>
                                                : formik.values.accountType == 'Credit Line' ?
                                                    <>
                                                        <option value="INR">INR</option>
                                                    </>
                                                    : null
                                        }

                                    </Select>
                                    {formik.touched.currency && formik.errors.currency && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.currency}</Text>
                                    )}
                                </Box>
                            </Flex>
                            {formik.touched.companyName && selectedCompanyAccountTypes?.length == 1 && (
                                <Text fontSize={'xs'} color={'orange.300'}>Please raise a ticket if you wish to change account type</Text>
                            )}

                            <Box flex={1}>
                                <Input
                                    name='creativeDriveLinks'
                                    placeholder='Creative Drive Links'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.creativeDriveLinks}
                                    isInvalid={formik.touched.creativeDriveLinks && formik.errors.creativeDriveLinks}
                                />
                                {formik.touched.creativeDriveLinks && formik.errors.creativeDriveLinks && (
                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                        {formik.errors.creativeDriveLinks}
                                    </Text>
                                )}
                            </Box>
                            <Box flex={1}>
                                <Input
                                    name='verifiedBmId'
                                    placeholder='Verified BM ID*'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.verifiedBmId}
                                    isInvalid={formik.touched.verifiedBmId && formik.errors.verifiedBmId}
                                />
                                {formik.touched.verifiedBmId && formik.errors.verifiedBmId && (
                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                        {formik.errors.verifiedBmId}
                                    </Text>
                                )}
                            </Box>
                            <Box>
                                <FormLabel fontSize='sm' mb={'10px'}>
                                    Screenshot of business info of BM ID <Text display={'inline-flex'} fontSize={'18px'} color={'red.400'}>*</Text>
                                </FormLabel>
                                <FileUploadBox
                                    fileSource={getFileSource('screenshotOfBusinessInfo')}
                                    multiUpload={false}
                                    fileKey='screenshotOfBusinessInfo'
                                    onFileUpload={handleFileUpload}
                                    onRemoveClick={() => removeFileSource('screenshotOfBusinessInfo')}
                                    isInvalid={formik.touched.screenshotOfBusinessInfo && formik.errors.screenshotOfBusinessInfo ? true : false}
                                //isUploading={newTopUpResponse.isLoading}
                                />
                                {formik.touched.screenshotOfBusinessInfo && formik.errors.screenshotOfBusinessInfo && (
                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                        {formik.errors.screenshotOfBusinessInfo}
                                    </Text>
                                )}
                            </Box>
                            <Box>
                                <FormLabel fontSize='sm' mb={'10px'}>
                                    Landing page
                                </FormLabel>
                                <Input
                                    name='landingPage'
                                    placeholder="Input your company's website URL"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.landingPage}
                                    isInvalid={formik.touched.landingPage && formik.errors.landingPage}
                                />
                                {/* <FileUploadBox
                                    fileSource={getFileSource('landingPage')}
                                    multiUpload={false}
                                    fileKey='landingPage'
                                    onFileUpload={handleFileUpload}
                                    onRemoveClick={() => removeFileSource('landingPage')}
                                    isInvalid={formik.touched.landingPage && formik.errors.landingPage ? true : false}
                                //isUploading={newTopUpResponse.isLoading}
                                /> */}
                                {formik.touched.landingPage && formik.errors.landingPage && (
                                    <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                        {formik.errors.landingPage}
                                    </Text>
                                )}
                            </Box>
                            <Box flex={1}>
                                <Select
                                    flex={1}
                                    name="accountTimezone"
                                    placeholder='Account timezone*'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.accountTimezone}
                                    isInvalid={formik.touched.accountTimezone && formik.errors.accountTimezone}
                                >
                                    {timezones.map(({ text }, index) => (
                                        <option key={`select-option-${index}`} value={text}>
                                            {text}
                                        </option>
                                    ))}

                                </Select>
                                {formik.touched.accountTimezone && formik.errors.accountTimezone && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.accountTimezone}</Text>
                                )}
                            </Box>



                            <Box flex={1}>
                                <Textarea
                                    size='md'
                                    name='description'
                                    placeholder='Enter the request text*'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    isInvalid={formik.touched.description && formik.errors.description ? true : false}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>{formik.errors.description}</Text>
                                )}
                            </Box>
                            <Box>
                                <Checkbox
                                    name="confirmationCheck"
                                    isChecked={formik.values.confirmationCheck}
                                    onChange={formik.handleChange}
                                    size="sm"
                                    alignItems={"baseline"}
                                >
                                    I/We hereby solemnly affirm and declare that the
                                    information given herein above is true and correct to
                                    the best of my/our knowledge and belief and nothing has
                                    been concealed therefrom.
                                </Checkbox>
                                {formik.touched.confirmationCheck &&
                                    formik.errors.confirmationCheck && (
                                        <Text mt={"5px"} fontSize={"12px"} color={"red.600"}>
                                            {formik.errors.confirmationCheck}
                                        </Text>
                                    )}
                            </Box>
                            <Flex gap={'20px'} mb={'10px'} mt={'20px'} justify={'center'}>
                                <Button
                                    colorScheme='orange'
                                    isDisabled={!formik.values.confirmationCheck}
                                    onClick={formik.handleSubmit}>
                                    Submit
                                </Button>
                                <Button onClick={() => { formik.resetForm(); onClose() }}>
                                    Cancel
                                </Button>
                            </Flex>
                        </FormControl>

                    </ModalBody>
                </ModalContent>
            </Modal>
            <SidebarWithHeader>
                <Flex mb={'10px'} >
                    <Heading as='h1' size='xl'>Ad Accounts</Heading>
                    <Spacer />
                    <Button colorScheme='orange' onClick={onOpen}>
                        <AddIcon boxSize={3} />&nbsp;Request
                    </Button>
                </Flex>
                {/* <Text mb={'20px'} fontSize={'sm'}>Please refer to the Track Requests tab to view the status of your Ad Account requests.</Text> */}
                <Flex flexDirection={'column'} flex={'1'} >
                    <Tabs p={0} colorScheme='orange' >
                        <TabList>
                            <Tab fontWeight={'bold'}>REQUESTS</Tab>
                            <Tab fontWeight={'bold'}>ACTIVE ACCOUNTS</Tab>
                        </TabList>

                        {isAccountsDataLoading || isActiveAccountsDataLoading ?
                            <Spinner mt={'20px'} />
                            : (
                                <TabPanels>
                                    <TabPanel px={0}>
                                        {adAccountsData?.data?.length > 0 ? (
                                            <AppTableWithPagination
                                                columns={RequestColumns}
                                                data={adAccountsData?.data}
                                                searchTerm={searchValue}
                                                paginationMeta={adAccountsData?.meta}
                                                onPageChange={(index) => setCurrentPage(index)}
                                                onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
                                                searchEnabled={true}
                                                isFetching={isFetching}
                                            />
                                        ) : (
                                            <Text>No requests yet</Text>
                                        )}

                                    </TabPanel>
                                    <TabPanel px={0}>
                                        {activeAccountsData?.data?.length > 0 ? (
                                            <AppTableWithPagination
                                                columns={AccountColumns}
                                                data={activeAccountsData?.data}
                                                searchTerm={searchValueForActiveAccounts}
                                                paginationMeta={activeAccountsData?.meta}
                                                onPageChange={(index) => setCurrentPageForActiveAccounts(index)}
                                                onSearchInputChange={(searchInput) => setsearchValueForActiveAccounts(searchInput)}
                                                searchEnabled={true}
                                                isFetching={isActiveAccountsFetching}
                                            />
                                        ) : (
                                            <Text>No active accounts yet</Text>
                                        )}

                                    </TabPanel>

                                </TabPanels>
                            )}

                    </Tabs>
                </Flex>
            </SidebarWithHeader>
        </>
    )
}

export default AccountListForClient