import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightElement, List, ListItem, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useGetDetailedTargetingQuery } from 'store/facebookServices/facebookServicesApi';

const DetailedTargetingSelector = ({ adsAccounts, onInterestsChange, preFilledTargets }) => {
    const inputRef = useRef(null);
    const popupRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [interestOptions, setInterestOptions] = useState([]);
    const [selectedInterests, setselectedInterests] = useState(preFilledTargets?.length > 0 ? preFilledTargets : []);
    const [showPopup, setShowPopup] = useState(false);

    const { data: interests, isLoading, isFetching, refetch } = useGetDetailedTargetingQuery({ searchTerm: debouncedSearchTerm, adsAccountId: adsAccounts[0]?.adsAccountId || adsAccounts?.adsAccountId }, { skip: !debouncedSearchTerm?.length });

    useEffect(() => {
        if (searchTerm?.length == 0) {
            setDebouncedSearchTerm('');
            setShowPopup(false);
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length > 0 && (searchTerm == debouncedSearchTerm)) {
            refetch()
        }
    }, [searchTerm, debouncedSearchTerm])


    useEffect(() => {
        if (interests?.data?.length > 0) {
            setShowPopup(true);
            setInterestOptions(interests?.data);
        } else {
            setShowPopup(false);
        }
    }, [interests, isFetching]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                popupRef.current &&
                !popupRef.current.contains(event.target)
            ) {
                setShowPopup(false);
                setInterestOptions([]);
            }

        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInterestSelect = (interest) => {
        setselectedInterests(prevInterests => {
            const updatedInterests = [...prevInterests, interest];
            // Avoid state updates during render
            setTimeout(() => onInterestsChange(updatedInterests), 0);
            return updatedInterests;
        });
        setSearchTerm('');
        setDebouncedSearchTerm('');
        setShowPopup(false);
        setInterestOptions([]);
    };

    const removeInterestEntry = (id) => {
        setselectedInterests(prevInterests => {
            const updatedInterests = prevInterests.filter(interest => interest.id !== id);
            setTimeout(() => onInterestsChange(updatedInterests), 0);
            return updatedInterests;
        });
    };


    return (
        <Box>
            <Box my={'10px'}>
                {selectedInterests?.map((interest) => (
                    <Flex key={'selected' + interest?.id + interest?.audience_size_lower_bound} bg={'white'} align={'center'} justify={'space-between'} width={'full'} py={'5px'} px={'10px'} borderRadius={'md'} mb={'5px'}>

                        <Flex flex={1} pr={'10px'} align={'center'}>
                            <Flex justify={'space-between'} flex={1} align={'center'}>
                                <Text ml={'5px'} fontSize={'14px'} fontWeight={'bold'}>{interest?.name} </Text>
                                <Text ml={'5px'} fontSize={'12px'}>{interest?.type}</Text>
                            </Flex>
                        </Flex>
                        <CloseIcon _hover={{ cursor: 'pointer', color: 'red.400' }} boxSize={3} color='gray.500' onClick={() => removeInterestEntry(interest?.id)} />
                    </Flex>
                ))}
            </Box>
            <Box position="relative">
                <Flex>
                    <InputGroup >
                        <InputLeftElement height={'32px'}>
                            <SearchIcon boxSize={4} color='gray.500' />
                        </InputLeftElement>
                        <Input
                            ref={inputRef}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Add demographics, interests or behaviors"
                            autoComplete="off"
                            bg={'#fff'}
                            size={'sm'}
                            onFocus={() => {
                                setInterestOptions([]);
                                if (interestOptions?.length > 0) {
                                    setShowPopup(true)
                                }
                            }}
                        />
                        <InputRightElement height={'32px'}>
                            {isFetching ? (
                                <Spinner boxSize={3} />
                            ) : (<>
                                {searchTerm?.length > 0 ? (
                                    <CloseIcon boxSize={3} color='gray.500' onClick={() => { setSearchTerm(''); setDebouncedSearchTerm(''); }} />
                                ) : null}
                            </>)}
                        </InputRightElement>
                    </InputGroup>
                </Flex>

                <>
                    {showPopup && interestOptions.length > 0 && (
                        <List
                            ref={popupRef}
                            position="absolute"
                            top="100%"
                            width="100%"
                            border="1px solid #ccc"
                            backgroundColor="white"
                            zIndex="1000"
                            maxHeight="200px"
                            overflowY="auto"
                        >
                            {interestOptions.map((interest) => (
                                <ListItem
                                    key={interest.region_id + interest.name}
                                    p={'5px'}
                                    px={'10px'}
                                    cursor="pointer"
                                    _hover={{ backgroundColor: '#f0f0f0' }}
                                    onClick={() => handleInterestSelect(interest)}
                                >
                                    <Flex justify={'space-between'} >
                                        <Text fontSize={'14px'}>
                                            {interest.name}
                                        </Text>
                                        <Text fontSize={'12px'}>
                                            {interest.type}
                                        </Text>
                                    </Flex>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            </Box>

        </Box>
    );
};

export default DetailedTargetingSelector;
