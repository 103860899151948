import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Flex,
    Text,
    Input,
    Select,
    Button,
    Divider,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import { useEffect, useState } from 'react';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Ad name is required'),
    creative: Yup.object().shape({
        name: Yup.string().required('Creative name is required'),
        object_story_spec: Yup.object().shape({
            link_data: Yup.object().shape({
                message: Yup.string().required('Primary text is required'),
                link: Yup.string().url('Invalid URL').required('Website URL is required'),
                call_to_action: Yup.object().shape({
                    type: Yup.string().required('Call to action is required'),
                }),
                child_attachments: Yup.array().of(
                    Yup.object().shape({
                        link: Yup.string().url('Invalid URL').required('Website URL is required'),
                        name: Yup.string().required('Creative name is required'),
                        description: Yup.string().required('Description is required'),
                        image_hash: Yup.string().required('Image hash is required'),
                    })
                ).min(1, 'At least one creative is required'),
            }),
            page_id: Yup.string().required('Facebook Page ID is required'),
        }),
    }),
});

const CarouselAdUpdateForm = ({ adData, adsAccountData, onClose }) => {
    const toast = useToast();
    const { creative } = adData || {};
    const [initialValues, setInitialValues] = useState(null);

    const { data: adCreative, isFetching, isLoading, refetch } = useGetAdCreativeQuery(
        { creativeId: creative.id },
        { skip: !creative.id }
    );

    
    const [updateAd] = useUpdateAdMutation();

    useEffect(() => {
        if (adCreative) {
            const values = {
                name: adData?.name || "",
                creative: {
                    name: adCreative?.name || "",
                    object_story_spec: {
                        link_data: {
                            message: adCreative?.object_story_spec?.link_data?.message || "",
                            link: adCreative?.object_story_spec?.link_data?.link || "",
                            call_to_action: {
                                type: adCreative?.object_story_spec?.link_data?.call_to_action?.type || "",
                            },
                            child_attachments: adCreative?.object_story_spec?.link_data?.child_attachments || [
                                { link: "", name: "", description: "", image_hash: "" },
                                { link: "", name: "", description: "", image_hash: "" },
                            ],
                        },
                        page_id: adCreative?.object_story_spec?.page_id || "",
                    },
                },
            };
            setInitialValues(values);
        }
    }, [adCreative, adData]);

    const handleSubmit = (values, { setSubmitting }) => {
        const payload = {
            name: values.name,
            creative: {
                name: values.creative.name,
                object_story_spec: {
                    link_data: {
                        message: values.creative.object_story_spec.link_data.message,
                        link: values.creative.object_story_spec.link_data.link,
                        call_to_action: {
                            type: values.creative.object_story_spec.link_data.call_to_action.type,
                        },
                        child_attachments: values.creative.object_story_spec.link_data.child_attachments.filter(child => child.link),
                    },
                    page_id: values.creative.object_story_spec.page_id,
                },
                degrees_of_freedom_spec: {
                    creative_features_spec: {
                        standard_enhancements: {
                            enroll_status: "OPT_OUT",
                        },
                    },
                },
                adsAccount: {
                    id: adsAccountData._id,
                    adsAccountId: adsAccountData.adsAccountId,
                    adsAccountName: adsAccountData.adsAccountName
                },
            },
        };

        updateAd({ creativeId: adData.id, payload })
            .unwrap()
            .then(response => {
                toast({
                    position: "top-right",
                    title: "Ad updated",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                console.log('Ad updated successfully:', response);
            })
            .catch(error => {
                toast({
                    position: "top-right",
                    title: "Error updating ad",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setSubmitting(false);
                onClose();
            });
    };

    if (!initialValues) {
        return <Spinner mt={'20px'} />;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {formik => (
                <Form>
                    <Box width="100%" maxWidth="600px" >
                        <Box bg="gray.50" p={4} borderRadius="md">
                            <Flex direction="column" mb={4} >
                                <Text fontWeight="bold" mb={'10px'}>Ad name</Text>
                                <Field
                                    name="name"
                                    as={Input}
                                    placeholder="Enter your ad name here..."
                                    bg="#fff"
                                    size='sm'
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                                )}
                            </Flex>

                            <Flex gap={'20px'}>
                                <Flex direction="column" mb={4} flex={1}>
                                    <Text fontWeight="bold" mb={'10px'}>Ads Account</Text>
                                    <Input
                                        bg="#e2e8f0"
                                        value={adsAccountData.adsAccountName || ""}
                                        isDisabled
                                        size='sm'
                                    />
                                </Flex>

                                <Flex direction="column" mb={4}>
                                    <Text fontWeight="bold">Facebook Page ID</Text>
                                    <FaceBookPageSelector
                                        adsAccount={adsAccountData}
                                        selectedPageId={formik.values.creative.object_story_spec.page_id}
                                        onSelectedPage={(pageId) => {
                                            formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                                        }}
                                    />
                                    {formik.errors.creative?.object_story_spec?.page_id && (
                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                                    )}
                                </Flex>
                            </Flex>

                            <FieldArray name="creative.object_story_spec.link_data.child_attachments">
                                {({ remove, push }) => (
                                    <Box>
                                        <Text fontWeight="bold" mb={2}>Creatives</Text>
                                        {formik.values.creative.object_story_spec.link_data.child_attachments.map((child, index) => (
                                            <Box key={index} mb={4} borderWidth={1} p={4} borderRadius="md">
                                                <Flex direction="column" mb={2}>
                                                    <Text fontWeight="bold" mb={'10px'}>Headline</Text>
                                                    <Field
                                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.name`}
                                                        as={Input}
                                                        placeholder="Creative name"
                                                        size='sm'
                                                    />
                                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && (
                                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name}</Text>
                                                    )}
                                                </Flex>
                                                <Flex direction="column" mb={2}>
                                                    <Text fontWeight="bold" mb={'10px'}>Description</Text>
                                                    <Field
                                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.description`}
                                                        as={Input}
                                                        placeholder="Creative description"
                                                        size='sm'
                                                    />
                                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && (
                                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description}</Text>
                                                    )}
                                                </Flex>
                                                <Flex direction="column" mb={2}>
                                                    <Text fontWeight="bold" mb={'10px'}>Website URL</Text>
                                                    <Field
                                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.link`}
                                                        as={Input}
                                                        placeholder="Creative link"
                                                        size='sm'
                                                    />
                                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && (
                                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link}</Text>
                                                    )}
                                                </Flex>
                                                <Flex direction="column" mb={2}>
                                                    <Text fontWeight="bold" mb={'10px'}>Image Hash</Text>
                                                    <Field
                                                        name={`creative.object_story_spec.link_data.child_attachments.${index}.image_hash`}
                                                        as={Input}
                                                        placeholder="Enter image hash"
                                                        size={'sm'}
                                                    />
                                                    {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.image_hash && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.image_hash && (
                                                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.image_hash}</Text>
                                                    )}
                                                </Flex>
                                                <Button size="sm" colorScheme="red" onClick={() => remove(index)} mt={'10px'}>
                                                    Remove
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            colorScheme="orange"
                                            size={'sm'}
                                            onClick={() => push({ link: "", name: "", description: "", image_hash: "" })}
                                        >
                                            Add New
                                        </Button>
                                    </Box>
                                )}
                            </FieldArray>
                            <Divider my={'20px'} />
                            <Box>
                                <Box >
                                    <Flex direction="column" mb={4}>
                                        <Text fontWeight="bold" mb={'10px'}>Primary Text</Text>
                                        <Field
                                            name="creative.object_story_spec.link_data.message"
                                            as={Input}
                                            placeholder="Message"
                                            bg="#fff"
                                            size='sm'
                                        />
                                        {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                                        )}
                                    </Flex>

                                    <Flex direction="column" mb={4}>
                                        <Text fontWeight="bold" mb={'10px'}>Call to action</Text>
                                        <Field
                                            name="creative.object_story_spec.link_data.call_to_action.type"
                                            as={Select}
                                            bg="#fff"
                                            size='sm'
                                        >
                                            <option value="">Select Action</option>
                                            <option value="SHOP_NOW">Shop Now</option>
                                            <option value="LEARN_MORE">Learn More</option>
                                            <option value="SIGN_UP">Sign Up</option>
                                            <option value="DOWNLOAD">Download</option>
                                        </Field>
                                        {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type && formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                            <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type}</Text>
                                        )}
                                    </Flex>
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            size="sm"
                            colorScheme="orange"
                            isLoading={formik.isSubmitting}
                            isDisabled={!formik.isValid}
                            type="submit"
                            my={'20px'}
                        >
                            Save
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default CarouselAdUpdateForm;
