import { useMemo, useState, useEffect } from 'react';
import {
    Spinner,
    Text,
    Modal,
    ModalHeader,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    useDisclosure,
    Flex,
    Box,
    Tooltip,
    Icon,
    Button,
    useToast,
    Switch,
    FormControl,
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import { FiEdit2 } from "react-icons/fi";
import { useGetAdsListQuery, useGetAdCreativeQuery, useUpdateAdStatusMutation } from 'store/campaigns/adsApi';
import CarouselAdUpdateForm from 'components/genericForms/CarouselAdUpdateForm';
import SingleAdUpdateForm from 'components/genericForms/SingleAdUpdateForm';

function AdsTable({ adsAccountData }) {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAd, setSelectedAd] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const { data: adsList, isFetching, isLoading, refetch } = useGetAdsListQuery({
        currentPage,
        searchValue,
        adsAccountId: adsAccountData?.adsAccountId
    }, { skip: !adsAccountData?.adsAccountId });

    const { data: adCreative, isFetching: isFetchingCreative, isLoading: isLoadingCreative, refetch: refetchCreative } = useGetAdCreativeQuery(
        { creativeId: selectedAd?.creative?.id },
        { skip: !selectedAd?.creative?.id || !selectedAd }
    );

    const [updateAdStatus, { isLoading: isAdStatusUpdating }] = useUpdateAdStatusMutation({});

    useEffect(() => {
        if (adsAccountData?.adsAccountId) {
            refetch();
        }
    }, [adsAccountData?.adsAccountId]);

    useEffect(() => {
        if (adsList?.data) {
            const initialSwitchStates = {};
            adsList.data.forEach(ad => {
                initialSwitchStates[ad.ad_id] = ad.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [adsList]);

    useEffect(() => {
        if (selectedAd) {
            refetchCreative();
        }
    }, [selectedAd]);

    const handleSwitchChange = (adId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adId]: newStatus }));
        updateAdStatus({
            adId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Ad ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Ad status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Ad status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adId]: currentState }));
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Adset ID",
                accessor: "adset_id",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isActive = switchStates[original.ad_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad is ON' : 'Ad is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Text>{original.adset_id}</Text>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.ad_id, original.status)}
                                    />
                                </FormControl>
                            </Tooltip>
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setSelectedAd(original);
                                            onOpen();
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.name}</Text>
                ),
            },
            {
                Header: "Creative ID",
                accessor: "creative id",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.creative.id}</Text>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text>{original.status}</Text>
                    )

                },
            },
        ],
        []
    );

    return (
        <>
            {/* EDIT AD MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isLoadingCreative ? (
                            <Spinner />
                        ) : adCreative?.object_story_spec?.link_data?.image_hash ? (
                            <SingleAdUpdateForm adData={selectedAd} adsAccountData={adsAccountData} onClose={onClose} />
                        ) : adCreative?.object_story_spec?.link_data?.child_attachments ? (
                            <CarouselAdUpdateForm adData={selectedAd} adsAccountData={adsAccountData} onClose={onClose} />
                        ) : (
                            <Text>No form available for the selected ad.</Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            {isLoading ? (
                <Spinner mt="20px" />
                ) : adsList?.data?.length > 0 ? (
                <AppTableWithPagination
                    columns={columns}
                    data={adsList.data}
                    searchEnabled={true}
                    isLoading={false}
                    isFetching={isFetching}
                    initialState={{ pageIndex: currentPage - 1 }}
                    paginationMeta={adsList.meta || {}}
                    onPageChange={setCurrentPage}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
                ) : (
                <Text>
                    No Ads created yet. Go to the Adspresso tab and click on <strong>'Create'</strong> to begin.
                </Text>
            )}
        </>
    );
}

export default AdsTable;
