import React, { useState } from 'react';
import {
    Box, Spinner, Text, useColorModeValue, useDisclosure, Grid, Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    Badge,
    Heading,
    Table,
    Tbody,
    TableContainer,
    Thead,
    Td,
    Tr,
} from '@chakra-ui/react';
import { useGetAdspressoAdsetsByCampainIdQuery } from 'store/adspresso/adspressoAdsetapi';
import SalesForm from './SalesForm';
import LeadsForm from './LeadsForm';
import { AddIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import { useGetAdsetFormQuery } from 'store/campaigns/adsetFormApi';

function AdSetForm({ campaignData }) {
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();
    const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

    const [selectedAdset, setSelectedAdset] = useState(null);
    const [isEditing, setisEditing] = useState(false);


    const addBoxBorder = useColorModeValue("gray.400", "gray.200");
    const addBoxHoverBackground = useColorModeValue("gray.50", "gray.700");
    const editIconColor = useColorModeValue("gray.600", "gray.400");
    const editIconHoverColor = useColorModeValue("gray.500", "gray.300");

    const campaignType = campaignData?.objective;
    const id = campaignData?._id;



    const { data: dataSales, error: errorSales, isLoading: isLoadingSales } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_SALES' }, { skip: campaignType !== 'OUTCOME_SALES' });
    const { data: dataLeads, error: errorLeads, isLoading: isLoadingLeads } = useGetAdsetFormQuery({ campaign_type: 'OUTCOME_LEADS' }, { skip: campaignType !== 'OUTCOME_LEADS' });

    const { data: adsetsListData, error: adsetsListError, isLoading: isLoadingadsetsList, refetch } = useGetAdspressoAdsetsByCampainIdQuery(id, {
        skip: !id,
    });

    const handleViewAdset = (adset) => {
        setSelectedAdset(adset);
        onViewOpen();
    };

    const handleSaveSuccess = () => {
        refetch();
    };

    const renderForm = () => {
        if (campaignType === 'OUTCOME_SALES' && dataSales) {
            return <SalesForm data={dataSales.data} adsetsListData={adsetsListData} campaignData={campaignData} onClose={onCreateClose} isEditing={isEditing} selectedAdset={selectedAdset} onEditModalClose={() => { setSelectedAdset(null); onEditModalClose(); }} onSaveSuccess={handleSaveSuccess} />;
        } else if (campaignType === 'OUTCOME_LEADS' && dataLeads) {
            return <LeadsForm data={dataLeads.data} adsetsListData={adsetsListData} campaignData={campaignData} onClose={onCreateClose} isEditing={isEditing} selectedAdset={selectedAdset} onEditModalClose={() => { setSelectedAdset(null); onEditModalClose(); }} onSaveSuccess={handleSaveSuccess} />;
        }
        return null;
    };

    const renderAdsetsList = () => {
        if (isLoadingadsetsList) {
            return <Spinner mt="20px" />;
        }

        if (adsetsListData?.data.length > 0) {
            return adsetsListData.data.map((adset, index) => {
                const { _id, name, daily_budget, bid_strategy, billing_event, optimization_goal, destination_type, status, targeting, promoted_object } = adset;

                return (
                    <Flex
                        borderWidth={1}
                        borderColor={addBoxBorder}
                        borderRadius={5}
                        p={"20px"}
                        w='90%'
                        minH={"150px"}
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                        key={_id}
                        pos={'relative'}
                    >
                        <Box>
                            <Heading as='h3' size={"sm"} mb={2}>{name}</Heading>
                            <Text fontSize={'12px'}>{_id.slice(0, 8)}...{id.slice(-4)}</Text>
                            {/* <Text fontSize={'14px'}>{_id}</Text>
                            <Text fontSize={'12px'} color={'gray.600'}>{optimization_goal}</Text> */}
                            {/* <Flex wrap={"wrap"} gap={"10px"} mt={3}>
                                <Badge colorScheme={status ? 'green' : 'red'}>{status ? 'Active' : 'Inactive'}</Badge>
                            </Flex> */}
                        </Box>
                        <Flex gap={"20px"} mt={"20px"}>
                            <Tooltip label='View' fontSize='xs'>
                                <ViewIcon
                                    transition={"0.2s ease"}
                                    color={editIconColor}
                                    _hover={{ cursor: "pointer", color: editIconHoverColor }}
                                    onClick={() => handleViewAdset({ _id, name, daily_budget, bid_strategy, billing_event, optimization_goal, destination_type, status, targeting, promoted_object })}
                                />
                            </Tooltip>
                            <Tooltip label='Edit' fontSize='xs'>
                                <EditIcon
                                    transition={"0.2s ease"}
                                    color={editIconColor}
                                    _hover={{ cursor: "pointer", color: editIconHoverColor }}
                                    onClick={() => { setSelectedAdset(_id); setisEditing(true); onEditModalOpen() }}
                                />
                            </Tooltip>
                        </Flex>
                    </Flex>
                );
            });
        } else {
            return <Text>Please add Ad Sets to run your campaign.</Text>;
        }
    };

    return (
        <>
            {/* CREATE ADSET MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isCreateOpen}
                scrollBehavior={"inside"}
                onClose={onCreateClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>New Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderForm()}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* EDIT ADSET MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isEditModalOpen}
                scrollBehavior={"inside"}
                onClose={() => { onEditModalClose(); setSelectedAdset(null); setisEditing(false); }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderForm()}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* VIEW ADSET MODAL */}
            <Modal
                size={"lg"}
                isCentered={true}
                isOpen={isViewOpen}
                scrollBehavior={"inside"}
                onClose={() => {
                    setSelectedAdset(null)
                    onViewClose()
                }
                }
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ad set Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedAdset ? (
                            <Flex gap={2} flexDirection={'column'}>
                                <TableContainer>
                                    <Table variant='simple' size={"sm"}>
                                        <Thead>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Ad set name</Td>
                                                <Td>{selectedAdset?.name}</Td>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {selectedAdset?.daily_budget ? (
                                                <Tr>
                                                    <Td w={"240px"} color='gray.500'>Daily budget</Td>
                                                    <Td>{selectedAdset?.daily_budget}</Td>
                                                </Tr>
                                            ) : null}

                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Optimization goal</Td>
                                                <Td>{selectedAdset?.optimization_goal}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Bid strategy</Td>
                                                <Td>{selectedAdset?.bid_strategy}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Billing event</Td>
                                                <Td>{selectedAdset?.billing_event}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Destination type</Td>
                                                <Td>{selectedAdset?.destination_type}</Td>
                                            </Tr>
                                            {selectedAdset?.promoted_object?.length > 0 ? (
                                                <Tr>
                                                    <Td w={"240px"} color='gray.500'>Pixel </Td>
                                                    <Td>
                                                        {selectedAdset?.promoted_object?.map((pixel, index) => (
                                                            <Box key={index + pixel?.adsAccountId}>
                                                                <Text>Ad Account: {pixel?.adsAccountId}</Text>
                                                                <Text>Pixel: {pixel?.pixel_id}</Text>
                                                                <Text>Event type: {pixel?.custom_event_type}</Text>
                                                            </Box>
                                                        ))}
                                                    </Td>
                                                </Tr>
                                            ) : null}

                                            <Tr>
                                                <Td w={"240px"} color='gray.500'>Status</Td>
                                                <Td>
                                                    <Badge colorScheme={selectedAdset?.status ? 'green' : 'red'}>
                                                        {selectedAdset?.status ? 'Active' : 'Inactive'}
                                                    </Badge>
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Flex>
                        ) : (
                            <Text>No details available</Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box p={'20px'} height="100%" display="flex" flexDirection="column">
                {(isLoadingSales || isLoadingLeads) ? (
                    <Spinner mt="20px" />
                ) : (
                    <Box flex="1" overflowY="auto" display="flex" flexDirection="column">
                        <Grid gridTemplateColumns={"repeat( auto-fill, minmax(120px, 200px) )"} gap={"20px"}>
                            <Flex
                                borderWidth={1}
                                borderColor={addBoxBorder}
                                borderRadius={5}
                                p={"20px"}
                                w='90%'
                                minH={"120px"}
                                _hover={{
                                    bg: addBoxHoverBackground,
                                    cursor: "pointer",
                                }}
                                alignItems={"center"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                                onClick={onCreateOpen}
                            >
                                <AddIcon boxSize={4} mb={2} />
                                <Text size={"md"}>Add New</Text>
                            </Flex>
                            {renderAdsetsList()}
                        </Grid>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default AdSetForm;
