import React from 'react';
import {
    Box,
    Text,
    Flex,
    useColorModeValue,
    CheckboxGroup,
    Checkbox,
    Spinner
} from '@chakra-ui/react';

function Sidebar({ isLoadingCampaign, campaignData }) {
    const bgColor = useColorModeValue('white', '#231f20');
    const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'gray.200');

    const { name, objective, adsAccounts } = campaignData || {};
    const selectedAdAccountIds = adsAccounts?.map(account => account.adsAccountId) || [];

    const getObjectiveName = (objective) => {
        switch (objective) {
            case 'OUTCOME_SALES':
                return 'Sales';
            case 'OUTCOME_LEADS':
                return 'Leads';
            default:
                return 'Unknown Objective';
        }
    };

    return (
        <Box
            height="100%"
            bg={bgColor}
            p={'20px'}
            px={'20px'}
            borderRight="1px solid"
            borderColor="gray.200"
            overflowY="auto"
        >
            {isLoadingCampaign ? (
                <Spinner mt={'20px'} />
            ) : (
                <Flex direction="column" spacing={4} align="flex-start" bg={'gray.50'} borderRadius="md">
                    {/* Campaign Name */}
                    {name ? (
                        <Flex
                            alignItems="center"
                            width="100%"
                            p={2}
                            borderRadius="md"
                        >
                            <Text
                                ml={2}
                                fontWeight="bold"
                                color={textColor}
                            >
                                {name}
                            </Text>
                        </Flex>
                    ) : (
                        <Flex
                            alignItems="center"
                            width="100%"
                        >
                            <Text
                                ml={2}
                                fontWeight="normal"
                                color={textColor}
                            >
                                Campaign Name not Available
                            </Text>
                        </Flex>
                    )}

                    {/* Campaign Objective */}
                    {objective ? (
                        <Flex
                            alignItems="center"
                            width="100%"
                            p={2}
                            borderRadius="md"
                        >
                            <Text
                                ml={2}
                                fontWeight="normal"
                                color={textColor}
                            >
                                {getObjectiveName(objective)}
                            </Text>
                        </Flex>
                    ) : (
                        <Flex
                            alignItems="center"
                            width="100%"
                            p={2}
                        >
                            <Text
                                ml={2}
                                fontWeight="normal"
                                color={textColor}
                            >
                                Objective not Available
                            </Text>
                        </Flex>
                    )}

                    {/* Ad Accounts */}
                    {adsAccounts && adsAccounts.length > 0 ? (
                        <Flex direction="column" ml={2} p={2}>
                            <Text mb={2} fontWeight="bold">Selected Ad Accounts</Text>
                            <CheckboxGroup value={selectedAdAccountIds} name="adsAccountName">
                                <Flex direction="column">
                                    {adsAccounts.map((account) => (
                                        <Checkbox
                                            key={account._id}
                                            colorScheme="orange"
                                            value={account.adsAccountId}
                                            isDisabled
                                        >
                                            <Text fontSize="14px" fontWeight="bold">{account.adsAccountName}</Text>
                                            <Text fontSize="12px" color="gray.500">{account.adsAccountId}</Text>
                                        </Checkbox>
                                    ))}
                                </Flex>
                            </CheckboxGroup>
                        </Flex>
                    ) : (
                        <Flex
                            direction="column"
                            ml={2}
                            p={2}
                        >
                            <Text mb={2} fontWeight="bold" color={textColor}>
                                Ad Accounts not Available
                            </Text>
                        </Flex>
                    )}
                </Flex>
            )}
        </Box>
    );
}

export default Sidebar;
