import moment from "moment";
import { api } from "../api";

export const campaignsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getcampaignsList: builder.query({
            query: ({ currentPage, searchValue, adsAccountId }) => ({
                url: `/campaigns/${adsAccountId}?page=${currentPage}&search=${searchValue}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Campaigns']
        }),
        getcampaignForm: builder.query({
            query: () => ({
                url: `/campaigns/form`,
            }),
        }),
        updateCampaign: builder.mutation({
            query: ({ campaignId, payload }) => ({
                url: `/campaigns/${campaignId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        getcampaignById: builder.query({
            query: ({ campaignId}) => ({
                url: `/campaigns/get/${campaignId}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['Campaigns']
        }),
        updateCampaignStatus: builder.mutation({
            query: ({ campaignId, payload }) => ({
                url: `/campaigns/update/status/${campaignId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        getCampaignInsights: builder.query({
            query: ({ campaignId, startDate, endDate }) => {

                let dateQuery = (moment(startDate)?.isValid() && moment(endDate)?.isValid()) ? `start_date=${startDate}&end_date=${endDate}` : ``;

                return ({
                    url: `/campaigns/insights/${campaignId}?${dateQuery}&limit=1`,
                })
            },
            transformResponse: (response, meta, arg) => response.data,
        }),
    }),
});

export const { useGetcampaignsListQuery, useGetcampaignFormQuery, useUpdateCampaignMutation, useGetCampaignInsightsQuery, useUpdateCampaignStatusMutation, useGetcampaignByIdQuery } = campaignsApi;


