import React from 'react';
import {
  Box,
  Button,
  Collapse,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Icon as ChakraIcon,
  Spinner,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { MdCheckCircle } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown, IoIosClose, IoMdCloseCircle } from "react-icons/io";

const FileUploadStatusWidget = ({ files, uploadStatus, onClose, isUploading, isWidgetOpen, onCancel, adsAccount }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  // Check if all files have been uploaded for each account
  const allFilesUploaded = files.every(file => {
    const accounts = Object.keys(uploadStatus[file.name] || {});
    return accounts.every(accountId => uploadStatus[file.name][accountId]?.status === "done");
  });

  const allUploaded = allFilesUploaded && !isUploading;

  if (!isWidgetOpen) {
    return null;
  }

  return (
    <Box
      position="fixed"
      bottom={0}
      right={0}
      width="100%"
      maxWidth="500px"
      maxHeight="400px"
      p={2}
      bg="#F4F4F4"
      color="black"
      boxShadow="md"
      zIndex="overlay"
      borderTopLeftRadius={20}
      borderTopRightRadius={20}
    >
      <HStack justifyContent="space-between" mb={2}>
        <Text fontSize="sm" fontWeight="bold">
        {files.length === 0
            ? 'No files selected'
            : allUploaded
              ? `(${files.length}) upload complete`
              : `Uploading ${files.length} files`}
        </Text>
        <Flex align={'center'}>
          <Button onClick={onToggle} variant="unstyled" size="sm">
            {isOpen ? <IoIosArrowDown size={24} /> : <IoIosArrowUp size={24} />}
          </Button>
          <Button onClick={onClose} variant="unstyled" size="sm">
            <IoIosClose size={30} />
          </Button>
        </Flex>
      </HStack>

      <Collapse in={isOpen}>
        <Box
          maxHeight="300px"
          overflowY="auto"
          pr={2}
        >
          <VStack spacing={2} align="stretch">
            {files.map((file) => {
              const statuses = uploadStatus[file.name] || {};
              const accounts = Object.keys(statuses);

              return (
                <Box
                  key={file.name}
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  p={'5px'}
                  borderBottom="1px solid gray"
                >
                  <Text fontSize="12px" fontWeight="bold" mb={1}>
                    {file.name} - {file.size}
                  </Text>
                  <VStack spacing={1} align="stretch">
                    {accounts.map((accountId) => {
                      const status = statuses[accountId] || {};
                      return (
                        <Box
                          key={accountId}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          p={'3px'}
                          borderRadius="md"
                          bg={status.status === "done" ? "green.50" : status.status === "failed" || status.status === "cancelled" ? "red.50" : "white"}
                          border={status.status === "uploading" ? "1px solid gray" : "none"}
                        >
                          <Text fontSize="10px" flex="1" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            Account {accountId}
                          </Text>
                          <Flex alignItems="center" ml={2}>
                            {status.status === "uploading" && (
                              <Flex alignItems="center" gap={2}>
                                <Spinner size={"sm"} thickness="4px" speed="0.65s" color="gray.800" emptyColor='gray.200' />
                                <Icon
                                  cursor={'pointer'}
                                  as={IoMdCloseCircle}
                                  size={16}
                                  color="red"
                                  onClick={() => onCancel(file.name, accountId)}
                                />
                              </Flex>
                            )}
                            {isUploading && status.status !== "uploading" ? (
                              <Spinner size={20} />
                            ) : status.status === "done" ? (
                              <ChakraIcon as={MdCheckCircle} color="green.500" boxSize={4} />
                            ) : status.status === "failed" || status.status === "cancelled" ? (
                              <Icon as={IoMdCloseCircle} size={16} color="red" />
                            ) : null}
                          </Flex>
                        </Box>
                      );
                    })}
                  </VStack>
                </Box>
              );
            })}
          </VStack>
        </Box>
      </Collapse>
    </Box>
  );
};

export default FileUploadStatusWidget;
