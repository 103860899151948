import { api } from "../api";

export const adsetFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdsetForm: builder.query({
            query: ({ campaign_type }) => ({
                url: `/adsets/form`,
                params: { campaign_type },
            }),
            providesTags: ['AdsetForm'],
        }),
        getAdsetsList: builder.query({
            query: ({ currentPage, searchValue, adsAccountId }) => ({
                url: `/adsets/${adsAccountId}?page=${currentPage}&search=${searchValue}`,
            }),
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ['AdsetForm'],
        }),
        updateAdset: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adsets/${adsetId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        updateAdsetStatus: builder.mutation({
            query: ({ adsetId, payload }) => ({
                url: `/adsets/update/status/${adsetId}`,
                method: 'PUT',
                body: payload
            }),
        }),
        getAdsetPlacements: builder.query({
            query: ({ optimization_goal, objective, buying_type, billing_event, account_id, destination_type, effective_device_platforms }) => ({
                url: `/adsets/ad_campaign_placement/`,
                params: {
                    optimization_goal,
                    objective,
                    buying_type,
                    billing_event,
                    account_id,
                    destination_type,
                    effective_device_platforms
                },
            }),
        }),
    }),
});

export const {
    useGetAdsetFormQuery,
    useGetAdsetsListQuery,
    useUpdateAdsetMutation,
    useGetAdsetPlacementsQuery,
    useUpdateAdsetStatusMutation,
} = adsetFormApi;
