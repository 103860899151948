import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    Switch,
    FormControl,
    FormLabel
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { useGetCampaignInsightsQuery, useGetcampaignsListQuery, useUpdateCampaignMutation, useUpdateCampaignStatusMutation } from 'store/campaigns/campaignsApi';
import * as Yup from 'yup';

function CampaignsTable({ adsAccountId, startDate, endDate }) {

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const [mergedInsightsData, setmergedInsightsData] = useState([]);
    const [switchStates, setSwitchStates] = useState({});

    const { data: campaignsList, isFetching, refetch, isLoading } = useGetcampaignsListQuery({
        currentPage,
        searchValue,
        adsAccountId
    }, { skip: !adsAccountId });

    // console.log(switchStates);

    //console.log(mergedInsightsData);


    const [updateCampaign, { isLoading: isCampaignUpdating }] = useUpdateCampaignMutation({});
    const [updateCampaignStatus, { isLoading: isCampaignStatusUpdating }] = useUpdateCampaignStatusMutation({});


    useEffect(() => {
        if (adsAccountId) {
            refetch();
        }
    }, [adsAccountId]);

    useEffect(() => {
        if (campaignsList?.data) {
            const initialSwitchStates = {};
            campaignsList.data.forEach(campaign => {
                initialSwitchStates[campaign.campaign_id] = campaign.status;
            });
            //console.log("🚀 ~ useEffect ~ initialSwitchStates:", initialSwitchStates)
            setSwitchStates(initialSwitchStates);
        }
    }, [campaignsList]);

    //console.log(campaignsList);



    useEffect(() => {
        if (selectedCampaign) {
            formik.setValues({
                name: selectedCampaign.name,
                // objective: selectedCampaign.objective  
            });
            //console.log({campaignsList});
        }
    }, [selectedCampaign]);

    const formik = useFormik({
        initialValues: {
            name: '',
            // objective: ''  
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Campaign name is required'),
            // objective: Yup.string().required('Objective is required')  
        }),
        onSubmit: (values) => {
            const { name } = values;
            const payload = {
                name: name,
                // objective: objective,  
            }
            updateCampaign({
                campaignId: selectedCampaign.campaign_id,
                payload
            }).unwrap()
                .then(() => {
                    refetch();
                    toast({
                        position: "top-right",
                        title: "Campaign updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    onClose();
                })
                .catch((error) => {
                    toast({
                        position: "top-right",
                        title: "Error updating campaign",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    console.error('Error updating campaign:', error);
                });
        }
    });

    const handleSwitchChange = (campaignId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [campaignId]: newStatus }));
        updateCampaignStatus({
            campaignId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Campaign ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating campaign status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating campaign status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [campaignId]: currentState }));
            });
    };


    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text>{original.name}</Text>
                    </Flex>
                ),
            },
            {
                Header: "Actions",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isActive = switchStates[original.campaign_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Campaign is ON' : 'Campaign is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.campaign_id, original.status)}
                                    />
                                </FormControl>
                            </Tooltip>
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setSelectedCampaign(original);
                                            onOpen();
                                        }}
                                    />
                                </Flex>
                            </Tooltip>

                        </Flex>
                    )
                },
            },
            {
                Header: "Campaign ID",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'}>
                        <Text fontSize={'12px'}>{original.campaign_id}</Text>
                    </Flex>
                )
            },
            {
                Header: "Ad Account ID",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'12px'}>{original.adsAccount.adsAccountId}</Text>
                )
            },
            {
                Header: "Ad Account Name",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'12px'}>{original.adsAccount.adsAccountName}</Text>
                )
            },
            {
                Header: "Objective",
                accessor: "objective",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    //console.log(original);

                    let objective = '';

                    if (original.objective === 'OUTCOME_SALES') {
                        objective = 'Sales';
                    } else if (original.objective === 'OUTCOME_LEADS') {
                        objective = 'Leads';
                    }

                    return <Text>{objective}</Text>;
                }
            },
            {
                Header: "cost per result",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'12px'}>{original.insights?.ctr}</Text>
                )
            },
            {
                Header: "Amount spent",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text fontSize={'12px'}>{original.insights?.spend}</Text>
                )
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    //console.log(original);

                    let statusCopy = '';
                    if (original.status == 'ARCHIVED') {
                        statusCopy = 'PUBLISHED';
                    } else {
                        statusCopy = original.status;
                    }
                    return (
                        <Text>{statusCopy}</Text>
                    )
                },
            },
        ],
        []
    );



    return (
        <>

            {/* EDIT CAMPAIGN MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Campaign</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={formik.handleSubmit}>
                            <Box width="100%" maxWidth="600px" bg="gray.50" p={4} borderRadius="md">
                                <Flex direction="column" >
                                    <Box mb={4}>
                                        <Flex align="center">
                                            <Text mb={2}>
                                                Campaign Name
                                            </Text>
                                        </Flex>
                                        <Input
                                            placeholder="Enter Campaign Name"
                                            bg="#fff"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <Text fontSize={'sm'} color="red.500">{formik.errors.name}</Text>
                                        )}
                                    </Box>
                                </Flex>

                                {/* Submit Button in a separate Flex container */}

                            </Box>
                            <Flex mt={4}>
                                <Button
                                    colorScheme="orange"
                                    isLoading={isCampaignUpdating}
                                    isDisabled={!formik.isValid}
                                    type="submit"
                                    size={'sm'}
                                    mb={'10px'}
                                >
                                    Save
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {isLoading ? (
                <Spinner mt="20px" />
            ) : campaignsList?.data?.length > 0 ? (
                <>
                    <AppTableWithPagination
                        columns={columns}
                        data={campaignsList?.data || []}
                        // data={mergedInsightsData || campaignsList?.data || []}
                        searchEnabled={true}
                        isLoading={false}
                        isFetching={isFetching}
                        initialState={{ pageIndex: currentPage - 1 }}
                        paginationMeta={campaignsList?.meta || {}}
                        onPageChange={setCurrentPage}
                        onSearchInputChange={setSearchValue}
                        searchTerm={searchValue}
                    />
                </>
            ) : (
                <Text>
                    No campaigns created yet. Go to the Adspresso tab and click on <strong>'Create'</strong> to begin.
                </Text>
            )}
        </>
    );
}

export default CampaignsTable;
