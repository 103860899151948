import { useEffect, useState } from "react";
import SidebarWithHeader from 'components/SidebarWithHeader';
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon, TimeIcon } from '@chakra-ui/icons';

import {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Flex,
    Text,
    Select,
    Icon,
    Box,
    Input
} from '@chakra-ui/react';
import { MdFolderOpen, MdWindow, MdOutlineFolderSpecial } from 'react-icons/md';
import { useGetActiveAccountsListQuery } from 'store/adAccounts/adAccountsApi';
import CampaignsTable from 'components/campaignTables/CampaignsTable';
import AdSetsTable from "components/campaignTables/AdSetsTable";
import AdsTable from "components/campaignTables/AdsTable";
import DatePicker from 'react-datepicker';
import moment from "moment";

function CampaignsForClient() {
    const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { data: activeAccountsData } = useGetActiveAccountsListQuery();

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            setSelectedAdsAccountId(activeAccountsData.data[0].adsAccountId);
            setSelectedAccountData(activeAccountsData.data[0]);
        }
    }, [activeAccountsData]);

    useEffect(() => {
        if (activeAccountsData?.data?.length > 0) {
            const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
            setSelectedAccountData(account);
        }
    }, [selectedAdsAccountId, activeAccountsData]);

    // console.log(startDate, endDate);



    return (
        <SidebarWithHeader>
            <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                <Flex gap={'10px'} flex={1} align={'center'}>
                    <Text fontWeight={'bold'}>Ad accounts</Text>
                    <Box flex={0.5}>
                        <Select
                            value={selectedAdsAccountId}
                            onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                            size={'sm'}
                            borderRadius={'md'}
                        >
                            <option value="">All Ad Accounts</option>
                            {activeAccountsData?.data?.map(account => (
                                <option key={account._id} value={account.adsAccountId}>
                                    {`${account.adsAccountName} (${account.adsAccountId})`}
                                </option>
                            ))}
                        </Select>
                    </Box>
                </Flex>

                <Flex justifyContent={'flex-end'} alignItems="center">
                    {/* <CalendarIcon mr={2} />
                        <DatePicker
                            selected={startDate}
                            onChange={handleChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            placeholderText="Select date range"
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            showPopperArrow={true}
                            className="custom-datepicker"
                            customInput={<Input placeholder="End Date" size={'sm'} width="100%" />}
                        /> */}
                    <Flex alignItems="center" gap="10px">
                        <Box >
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                                className="date-picker-input"
                                style={{ width: 'auto' }}
                            />
                        </Box>
                        <Box >
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                                className="date-picker-input"
                                style={{ width: 'auto', fontsize: '12px' }}
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
            <Tabs p={0} colorScheme='orange' variant='enclosed-colored'>
                <TabList>
                    <Tab fontWeight={'bold'} flex={1}><Icon as={MdFolderOpen} mr={2} />Campaigns</Tab>
                    <Tab fontWeight={'bold'} flex={1}><Icon as={MdWindow} mr={2} />Ad sets</Tab>
                    <Tab fontWeight={'bold'} flex={1}><Icon as={MdOutlineFolderSpecial} mr={2} />Ads</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px={0}>
                        <CampaignsTable
                            adsAccountId={selectedAdsAccountId}
                            startDate={moment(startDate).format("YYYY-MM-DD")}
                            endDate={moment(endDate).format("YYYY-MM-DD")}
                        />
                    </TabPanel>
                    <TabPanel px={0}>
                        <AdSetsTable adsAccountId={selectedAdsAccountId} />
                    </TabPanel>
                    <TabPanel px={0}>
                        <AdsTable adsAccountData={selectedAccountData} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </SidebarWithHeader >
    );
}

export default CampaignsForClient;
